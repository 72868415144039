.Root {
  background: #FFFFFF;
  border-radius: 6px;
  max-width: 190px;
  width: 100%;
  height: auto;
  box-shadow: 0 2px 30px rgba(178, 181, 194, 0.29);
  position: absolute;
  z-index: 1;
  left: -13px;
  pointer-events: all;

  ul {
    margin-top: 19px;
    margin-bottom: 19px;

    li {
      list-style: none;
      padding-left: 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
      height: 32px;
      display: flex;
      align-items: center;
      cursor: pointer;

      & > div {
        margin-left: 70px;
      }

      &:hover {
        color: #124C9A;
        background: rgba(67, 94, 237, 0.06);
      }
    }
  }
}
