@import "~@styles/breakpoints.scss";
@import '~@styles/palette.scss';

@mixin ActiveTab {
  padding: 17px 0 14px;
  height: 28px;
  border-radius: 10px 10px 0 0;
  position: relative;
  border: none;
  @media only screen and (max-width: $xs) {
    z-index: 1;
    border-radius: unset;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    height: 42px;
  }

  button {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    @media only screen and (max-width: $sm) {
      font-size: 2.1vw;
      padding: 2vw 3vw;
    }
    @media only screen and (max-width: $xs) {
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
    }
  }
}

@mixin ActiveTabCircle {
  content: "";
  max-width: 10px;
  width: 100%;
  background: #F8F8F8;
  position: absolute;
  height: 10px;
  bottom: 0;
  border-radius: 50%;
  z-index: 2;
}

@mixin ActiveTabSquare {
  content: "";
  max-width: 4px;
  width: 100%;
  position: absolute;
  height: 5px;
  bottom: 0;
}

.Navigation {
  display: flex;
  align-items: flex-end;
  height: 51px;
  background: #F8F8F8;
  margin-top: 19px;
  border-bottom: 2px solid $blue;
  @media only screen and (max-width: $xs) {
    width: 100%;
    position: absolute;
    top: auto;
    left: 0;
    height: 42px;
    border: none;
    align-items: center;
    justify-content: center;
    margin: 50px 0 0 0;
  }

  .Controls {
    display: none;
    @media only screen and (max-width: $xs) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    &Prev, &Next {
      display: flex;
      background-color: #124C9A;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      margin: 0;
      padding: 0;
      &[data-show = "false"] {
        opacity: 0;
        pointer-events: none;
      }
    }
    &Prev {
      background-image: url("~@assets/prev_white.svg");
      margin-left: 20px;
    }
    &Next {
      background-image: url("~@assets/next_white.svg");
      margin-right: 20px;
    }
  }

  .Tab {
    height: 49px;
    border-left: 1px solid #ECECEC;
    border-right: 1px solid #ECECEC;
    border-radius: 0;
    display: flex;
    align-items: center;
    list-style: none;
    @media only screen and (max-width: $xs) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 42px;
      z-index: 0;
      border: none;
      border-radius: unset;
      &:before, &:after {
        display: none !important;
      }
    }

    button {
      background: transparent;
      font-size: 16px;
      line-height: 28px;
      color: #828282;
      padding: 10px 29px;
      @media only screen and (max-width: $sm) {
        font-size: 2.1vw;
        padding: 2vw 3vw;
      }
      @media only screen and (max-width: $xs) {
        font-weight: bold;
        font-size: 16px;
        line-height: 28px;
        &:before, &:after {
          display: none !important;
        }
      }
    }
  }

  .ActiveBlueTab {
    @include ActiveTab;

    background: #20396F;

    &:after {
      @include ActiveTabCircle;
      right: -10px;
    }

    button:after {
      @include ActiveTabSquare;
      background: #20396F;
      right: -4px;
    }
  }

  .ActiveRedTab {
    @include ActiveTab;

    background: $red;

    &:before {
      @include ActiveTabCircle;
      left: -10px;
    }

    &:after {
      @include ActiveTabCircle;
      right: -10px;
    }

    button:before {
      @include ActiveTabSquare;
      background: $red;
      left: -4px;
    }

    button:after {
      @include ActiveTabSquare;
      background: $red;
      right: -4px;
    }
  }

  .ActiveGreenTab {
    @include ActiveTab;

    background: $green;

    &:before {
      @include ActiveTabCircle;
      left: -10px;
    }

    button:before {
      @include ActiveTabSquare;
      background: $green;
      left: -4px;
    }
  }
}

.NavigationBorderBlueBottom {
  border-color: $blue;
}

.NavigationBorderRedBottom {
  border-color: $red;
}

.NavigationBorderGreenBottom {
  border-color: $green;
}
