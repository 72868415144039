@import "~@styles/palette.scss";

.Root {
  margin-bottom: 34px;
  position: relative;

  &Actions {
    display: flex;
    column-gap: 32px;
  }

  .TitleContainer {
    display: flex;
    align-items: center;
    cursor: pointer;

    &Img {
      width: 24px;
      height: 24px;
      margin-right: 15px;
      margin-top: -4px;
    }

    .ActiveCheckIcon {
      transform: rotate(180deg);
      margin-top: -4px;
    }

    svg:first-child {
      width: 22px;
      height: 28px;
      margin-top: -4px;
      margin-right: 15px;
    }

    h4 {
      font-size: 24px;
      line-height: 29px;
      color: $gray1;
      margin-right: 12px;
      font-family: 'AXP_BOLD', sans-serif;
    }

    svg:nth-of-type(2) {
      cursor: pointer;
    }
  }

  .AddInvited {
    background: transparent;
    margin-top: 28px;
    margin-bottom: 13px;
    display: flex;
    align-items: center;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $gray1;
    max-width: 170px;
    width: 100%;

    svg {
      margin-right: 12px;
      width: 25px;
      height: 25px;
      pointer-events: none;
    }

    span {
      pointer-events: none;
    }
  }

  .Invited {
    display: flex;
    height: 32px;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    box-sizing: content-box;

    &Checkbox {
      span {
        &::after {
          margin-left: 0!important;
          margin-top: 7px!important;
          margin-right: 10px!important;
        }

        &::before {
          right: 8px!important;
          top: 9px!important;
        }
      }
    }

    img {
      width: 32px;
      height: 32px;
      margin-right: 12px;
      border-radius: 50%;
    }

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: $gray1;

      span {
        color: #ACACAC;
      }
    }
  }
}

.InvitedList {
  border: none;
  min-width: 60px;
}
