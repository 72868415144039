@import "~@styles/breakpoints.scss";

@mixin activeFilterElement {
  svg {
    circle {
      fill: #fff;
    }
    path {
      fill: rgb(243, 148, 7);
    }
  }
}

@mixin filterElementContainer {
  cursor: pointer;
  display: flex;

  &[data-title] {
    position: relative;
  }

  &[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
  }

  &[data-title]:after {
    content: attr(data-title);
    background: #F2F2F2;
    border-radius: 11px;
    color: #333333;
    font-size: 16px;
    line-height: 14px;
    position: absolute;
    padding: 6px 9px 5px 10px;
    bottom: -1.6em;
    left: 100%;
    white-space: nowrap;
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
  }
}

@mixin filterElement {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-size: contain;
}

@mixin filterTitle {
  span {
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    color: #333333;
    padding-right: 2px;
    @media only screen and (max-width: $xs) {
      margin-bottom: 8px;
    }
  }
}
