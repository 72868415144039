@import "~@styles/breakpoints.scss";

.ImplementationTabs {
  display: flex;

  .ImplementationPageTabsTab {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #1CAB88;
    width: initial;
    background: transparent;
    border-radius: 0;
    height: 55px;
    padding: 0;
    cursor: initial;
    border-bottom: 2px solid #1CAB88;
  }
}

.MainInfo {
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 0;
  padding: 32px;
  margin-bottom: 52px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: $md) {
    flex-wrap: wrap;
  }

  .MainInfoContent {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 615px;
    width: 100%;
    margin-right: 50px;
    @media only screen and (max-width: $md) {
      margin-right: 0;
      flex: 1 auto;
      max-width: unset;
    }

    .MainInfoDescription {
      & > p > strong {
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.0124em;
        color: #333333;
        margin: 0;
      }

      p, h5 {
        padding: initial;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.0124em;
        color: #333333;
        margin: 0;
      }

      ul, ol {
        li {
          font-size: 18px;
          line-height: 20px;
          letter-spacing: -0.0124em;
          color: #333333;
        }
      }
    }

    .MainInfoAdditionalInfo {
      max-width: 615px;
      width: 100%;

      p {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.0124em;
        color: #333333;
        margin: 30px 0 0;
      }

      span {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.0124em;
        color: #333333;
      }
    }

    .MainInfoFaqInfo {
      display: flex;
      margin-top: 30px;

      p {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.0124em;
        color: #333333;
        margin: 0;
        font-family: 'AXP_LIGHT', sans-serif;
      }
    }

    .MainInfoImgsContent {
      display: flex;
      flex-direction: column;

      img {
        max-width: 615px;
        width: 100%;
      }
    }
  }

  .MainInfoFaq {
    max-width: 250px;
    width: 100%;
    height: fit-content;
    margin-top: 137px;
    box-sizing: content-box;
    border-radius: 12px;
    margin-bottom: 90px;
    @media only screen and (max-width: $xl) {
      max-width: 17vw;
    }
    @media only screen and (max-width: $md) {
      margin-top: 40px;
      margin-bottom: 40px;
      max-width: unset;
    }
    @media only screen and (max-width: $sm) {
      max-width: 45vw;
    }
    @media only screen and (max-width: $sm) {
      max-width: unset;
    }

    .MainInfoFaqContent {
      font-weight: 500;
      font-size: 19.2998px;
      line-height: 21px;
      color: #333333;
      max-width: 317px;
      width: 100%;
      padding: 36px;
      position: relative;
      background: #E6F8E9;
      border-radius: 12px;
      @media only screen and (max-width: $sm) {
        max-width: unset;
        width: auto;
      }

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 0 20px 20px;
        border-color: transparent transparent transparent #E6F8E9;
        position: absolute;
        right: -20px;
        top: 20px;
        z-index: 1;
        @media only screen and (max-width: $xs) {
          display: none;
        }
      }

      &:after {
        content: "";
        background: url("~@assets/woman_green.svg") center center no-repeat;
        background-size: contain;
        width: 10vw;
        height: 132px;
        position: absolute;
        top: 0px;
        right: -11vw;
        @media only screen and (max-width: $xs) {
          display: none;
        }
      }
    }
  }

  .MainInfoExpertOpinion {
    max-width: 480px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: $md) {
      margin-right: 0;
      flex: 1 auto;
      max-width: unset;
    }

    &:before {
      content: "";
      background: url("~@assets/expert.svg") 0 0 no-repeat;
      background-size: contain;
      width: 94px;
      height: 94px;
      max-width: 94px;
      max-height: 94px;
      position: absolute;
      top: 10px;
      right: 0;
      @media only screen and (max-width: $md) {
        width: 13vw;
        height: 13vw;
      }
      @media only screen and (max-width: $xs) {
        width: 57px;
        height: 57px;
        position: relative;
      }
    }

    .MainInfoExpertOpinionText {

      .MainInfoExpertOpinionTextTitle {
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: #1CAB88;
        margin: 0;
        max-width: 357px;
        width: 100%;
      }

      & > p {
        font-size: 18px;
        line-height: 28px;
        margin: 0;
        max-width: 357px;
        width: 100%;
        @media only screen and (max-width: $md) {
          max-width: 80%;
        }
      }

      & > span {
        font-size: 18px;
        line-height: 28px;
        color: #333333;
        max-width: 357px;
        width: 100%;
        display: block;
        @media only screen and (max-width: $md) {
          max-width: 80%;
        }
      }

      .MainInfoExpertOpinionConclusion {
        margin-top: 24px;

        & > p {
          font-size: 18px;
          line-height: 28px;
          margin: 0;
          max-width: 357px;
          width: 100%;
          @media only screen and (max-width: $md) {
            max-width: 80%;
          }
        }

        .MainInfoExpertOpinionClosing {
          font-family: 'AXP_BOLD', sans-serif;
          font-size: 18px;
          line-height: 20px;
          color: #333333;
          margin: 0;
          max-width: 376px;
          width: 100%;
        }

        .MainInfoExpertOpinionClosingTwo {
          p {
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
