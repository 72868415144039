@mixin ContainerLg {
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
}

@mixin Scroll {
  overflow-y: scroll;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    border-radius: 0;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #E1E1E1;
  }
}

@mixin ScrollX {
  overflow-x: scroll;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    border-radius: 0;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #E1E1E1;
  }
}
