@import "~@styles/breakpoints.scss";
@import "~@components/Filtration/Filtration.scss";

.FilterElementApplicationArea {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > span {
    margin-right: 11px;
  }

  @media only screen and (max-width: $xs) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include filterTitle;

  .FilterElementApplicationAreaContent {
    display: flex;
    column-gap: 4px;

    @media only screen and (max-width: $xs) {
      margin: 0 0 0 0;
    }
  }
}

.FilterElement {
  display: initial;

  &squares {
    background: url("~@assets/filter_icons/filter_fountain.svg") 0 0 no-repeat;

    @include filterElementContainer;
    @include filterElement;

    &:hover {
      background: url("~@assets/filter_icons/filter_fountain_focus.svg") 0 0 no-repeat;
    }
  }

  &squaresActive {
    background: url("~@assets/filter_icons/filter_fountain_active.svg") 0 0 no-repeat;

    &:hover {
      background: url("~@assets/filter_icons/filter_fountain_active.svg") 0 0 no-repeat;
    }
  }

  &yard {
    background: url("~@assets/filter_icons/filter_courtyard.svg") 0 0 no-repeat;;

    @include filterElement;
    @include filterElementContainer;

    &:hover {
      background: url("~@assets/filter_icons/filter_courtyard_focus.svg") 0 0 no-repeat;
    }
  }

  &yardActive {
    background: url("~@assets/filter_icons/filter_courtyard_active.svg") 0 0 no-repeat;

    &:hover {
      background: url("~@assets/filter_icons/filter_courtyard_active.svg") 0 0 no-repeat;
    }
  }

  &embankments {
    background: url("~@assets/filter_icons/filter_embankment.svg") 0 0 no-repeat;;

    @include filterElement;
    @include filterElementContainer;

    &:hover {
      background: url("~@assets/filter_icons/filter_embankment_focus.svg") 0 0 no-repeat;
    }
  }

  &embankmentsActive {
    background: url("~@assets/filter_icons/filter_embankment_active.svg") 0 0 no-repeat;

    &:hover {
      background: url("~@assets/filter_icons/filter_embankment_active.svg") 0 0 no-repeat;
    }
  }

  &streets {
    background: url("~@assets/filter_icons/filter_street.svg") 0 0 no-repeat;;

    @include filterElement;
    @include filterElementContainer;

    &:hover {
      background: url("~@assets/filter_icons/filter_street_focus.svg") 0 0 no-repeat;
    }
  }

  &streetsActive {
    background: url("~@assets/filter_icons/filter_street_active.svg") 0 0 no-repeat;

    &:hover {
      background: url("~@assets/filter_icons/filter_street_active.svg") 0 0 no-repeat;
    }
  }

  &facades {
    background: url("~@assets/filter_icons/filter_facade.svg") 0 0 no-repeat;;

    @include filterElement;
    @include filterElementContainer;

    &:hover {
      background: url("~@assets/filter_icons/filter_facade_focus.svg") 0 0 no-repeat;
    }
  }

  &facadesActive {
    background: url("~@assets/filter_icons/filter_facade_active.svg") 0 0 no-repeat;

    &:hover {
      background: url("~@assets/filter_icons/filter_facade_active.svg") 0 0 no-repeat;
    }
  }

  &parks {
    background: url("~@assets/filter_icons/filter_park.svg") 0 0 no-repeat;;

    @include filterElement;
    @include filterElementContainer;

    &:hover {
      background: url("~@assets/filter_icons/filter_park_focus.svg") 0 0 no-repeat;
    }
  }

  &parksActive {
    background: url("~@assets/filter_icons/filter_park_active.svg") 0 0 no-repeat;

    &:hover {
      background: url("~@assets/filter_icons/filter_park_active.svg") 0 0 no-repeat;
    }
  }
}
