@import "~@styles/breakpoints.scss";
@import "~@styles/mixins.scss";
@import "~@styles/palette.scss";

.Root {
  height: 90px;
  position: fixed;
  width: 100%;
  background: #124C9A url("~@assets/backgrounds/header.svg") no-repeat center center;
  display: flex;
  align-items: center;
  z-index: 3;
  border-bottom: 1px solid #345AC1;

  @media only screen and (max-width: $xs) {
    height: auto;
    padding: 8px 0 10px 0;
  }

  &SectionsMobile {
    height: 100vh;
    background: #fff;
    width: 100%;
    position: absolute;
    top: 90px;
    display: none;

    @media only screen and (max-width: $sm) {
      display: flex;
    }

    @media only screen and (max-width: $xs) {
      display: flex;
      top: 70px;
    }

    &[data-active="true"] {
      @media only screen and (max-width: $sm) {
        top: 90px;
      }

      @media only screen and (max-width: $xs) {
        top: 130px;
      }
    }

    &Container {
      @include ContainerLg;
      justify-content: center;
      display: flex;

      &Levels {
        max-width: initial;
        width: 100%;
        border-right: 1px solid #EDEDED;

        ul {
          padding-top: 31px;

          li {
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.0125em;
            color: #333333;
            font-weight: bold;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
              color: #124C9A;
              background: rgba(67, 94, 237, 0.06);
            }
          }
        }
      }

      &Categories {
        padding: 32px;
        max-width: 100vw;
        width: 100%;
        @include Scroll;

        &Container {
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;
          position: relative;

          & > svg {
            cursor: pointer;
            margin-top: 4px;
            position: absolute;
            right: 0;
          }

          &[data-active="true"] {
            & > svg {
              transform: rotate(180deg);
            }
          }

          &Category {
            text-decoration: none;
            color: #242424;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            padding-right: 20px;
          }

          &Passport {
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            margin-left: 12px;

            a {
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: #7D7D7D;
              text-decoration: none;
              text-transform: lowercase;
            }

            a:first-letter {
              text-transform: uppercase;
            }
          }
        }

        &Btn {
          background: transparent;
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #333333;
          margin-bottom: 32px;

          svg {
            margin-right: 12px;
            width: 24px;
            height: 26px;
            margin-top: -4px;
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          margin-bottom: 150px;
        }
      }
    }
  }

  &Sections {
    height: 100vh;
    background: #fff;
    width: 100%;
    position: absolute;
    top: 90px;
    display: flex;

    @media only screen and (max-width: $sm) {
      display: none;
    }

    &Container {
      @include ContainerLg;

      display: flex;
    }

    &LeftMenu {
      max-width: 202px;
      width: 100%;
      border-right: 1px solid #EDEDED;

      @media only screen and (max-width: $sm) {
        max-width: initial;
      }

      ul {
        padding-top: 31px;

        li {
          font-size: 18px;
          line-height: 22px;
          letter-spacing: -0.0125em;
          color: #333333;
          font-weight: bold;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            color: #124C9A;
            background: rgba(67, 94, 237, 0.06);
          }
        }
      }
    }

    &RightMenu {
      width: 100%;
      padding-left: 51px;
      display: flex;
      height: inherit;
      padding-top: 45px;
      overflow-y: auto;
      @include Scroll;


      @media only screen and (max-width: $sm) {
        display: none;
      }

      &Link {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #242424;
        padding-bottom: 8px;
        font-family: 'AXP_BOLD', sans-serif;
        text-decoration: none;
        max-width: 187px;
        width: 100%;
      }

      &SubLink {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #7D7D7D;
        padding-top: 8px;
        padding-bottom: 8px;
        text-decoration: none;
        max-width: 187px;
        width: 100%;

        &:hover {
          color: #124C9A;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        column-gap: 47px;
        max-width: 100vw;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 200px;
        @media only screen and (max-width: $md) {
          column-gap: 60px;
        }

        &[data-level="2"] {
          height: 100vh;
        }

        &[data-level="1"] {
          height: 2000px;
        }

        &[data-level="0"] {
          height: 120vh;
        }

        &[data-level="-1"] {
          height: 100vh;
        }

        & > span {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: 90%;
          gap: 0;
          width: 100%;
        }

        li {
          list-style: none;
          text-transform: lowercase;
          max-width: 208px;
          width: 100%;
          margin-right: 60px;
          box-sizing: border-box;
          cursor: pointer;


          &:first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .Container {
    display: flex;
    align-items: center;

    @include ContainerLg;

    padding-left: 40px;
    padding-right: 40px;
    box-sizing: content-box;
    flex-wrap: wrap;
    justify-content: space-between;
    @media only screen and (max-width: $xs) {
      padding-left: 20px;
      padding-right: 20px;
    }

    .Logo {
      position: relative;
      display: flex;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      overflow: hidden;
      @media only screen and (max-width: $sm) {
        width: 40px;
        height: 40px;
      }
      @media only screen and (max-width: $xs) {
        order: 2;
      }

      .LogoIcon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url("~@assets/logo.png") no-repeat center center;
        background-size: contain;
      }
    }

    .SearchGroupTrigger {
      display: none;

      @media only screen and (max-width: $xs) {
        order: 1;
        display: flex;
        align-items: center;
        color: white;
        background: transparent;
        width: auto;
        
        &[data-opened = "true"] {
          .SearchGroupTriggerIcon {
            background-image: url("~@assets/close_white.svg");
          }
        }
      }

      @media only screen and (max-width: $xs) {
        margin-left: 0%;

        span {
          display: none;
        }
      }

      .SearchGroupTriggerIcon {
        background-image: url("~@assets/search_white.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 18px;
        height: 20px;
      }
    }

    .SearchGroup {
      display: flex;
      width: auto;
      position: relative;
      flex: 1 auto;
      @media only screen and (max-width: $xs) {
        display: none;
        height: 0;
        //overflow: hidden;
        order: 4;
        width: 100%;
        &[data-active = "true"] {
          display: flex;
          height: 40px;
          margin-top: 19px;
        }
      }
    }

    .Sections  {
      width: auto;
      max-width: 109px;
      height: 40px;
      background: #2066C5;
      color: white;
      margin-right: 8px;
      margin-left: 65px;
      display: flex;
      align-items: center;

      @media only screen and (max-width: $lg) {
        margin-right: 0.6%;
        margin-left: 4.8%;
      }
      @media only screen and (max-width: $sm) {
        margin-right: 1.1%;
        margin-left: 2.9%;
      }
      @media only screen and (max-width: $xs) {
        span {
          display: none;
        }
      }

      .Burger {
        background: none;
        border: 0;
        cursor: pointer;
        margin: 10px 10px 10px 5px;
        position: relative;
        width: 20px;
        height: 20px;
        background: url("~@assets/sections.svg") 50% 50% no-repeat;
        background-size: contain;
        @media only screen and (max-width: $xs) {
          margin: 10px 5px 10px 5px;
        }

        &.Active {
          background: url("~@assets/close_white.svg") 50% 50% no-repeat;
          background-size: contain;
        }

      }
    }

    .SearchContainer {
      display: flex;
      width: 100%;
      position: relative;
      max-width: 521px;

      .SearchContainerResultsEmpty {
        display: flex;
        padding-top: 14px;
        align-items: center;
        background: #FBFBFB;
        width: 98%;
        position: absolute;
        top: 43px;
        left: 8px;
        border-radius: 0 0 6px 6px;
        box-shadow: 0 2px 30px rgba(178, 181, 194, 0.29);

        @media only screen and (max-width: $lg) {
          width: 97.5%;
        }

        @media only screen and (max-width: $md) {
          width: 98.4%;
        }

        @media only screen and (max-width: $sm) {
          width: 96.2%;
        }

        & > span {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: $gray2;
          padding-left: 24px;
        }

        svg {
          margin-left: 12px;
        }
      }

      .SearchContainerResults {
        width: 98%;
        position: absolute;
        border-radius: 0 0 6px 6px;
        top: 43px;
        background: #FBFBFB;
        left: 8px;
        padding: 18px 0 24px 0;
        box-shadow: 0 2px 30px rgba(178, 181, 194, 0.29);
        max-height: 600px;
        overflow-y: auto;

        @media only screen and (max-width: $lg) {
          width: 97.5%;
        }

        @media only screen and (max-width: $md) {
          width: 98.4%;
        }

        @media only screen and (max-width: $sm) {
          width: 96.2%;
        }

        & > h4 {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: $gray3;
          padding-left: 24px;
          padding-bottom: 23px;
          font-family: 'AXP_LIGHT', sans-serif;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        padding-left: 24px;
        row-gap: 18px;
        padding-bottom: 20px;

        li {
          list-style: none;
          display: flex;
          align-items: center;

          a {
            text-decoration: none;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $gray1;
            display: inline-flex;
            align-items: center;
            column-gap: 12px;

            &:first-letter {
              text-transform: uppercase;
            }

            img {
              width: 28px;
              height: 28px;
              border-radius: 3px;
            }
          }
        }
      }

      @media only screen and (max-width: $md) {
        flex: 1;
      }

      form {
        width: 100%;
      }

      .Bird {
        position: absolute;
        right: -62px;
        top: -40px;
        background: url("~@assets/bird.svg") 0 0 no-repeat;
        width: 127px;
        height: 107px;
        z-index: 1;
      }

      .SearchInput {
        height: 42px;

        input {
          font-family: "Fira_Sans_Regular", sans-serif;
          display: flex;
          align-items: center;
          max-width: 521px;
          width: 100%;
          height: 42px;
          border: 1px solid #ECECEC;
          box-sizing: border-box;
          border-radius: 6px;
          font-size: 16px;
          line-height: 19px;
          color: #828282;
          margin-left: 8px;
          background: #FBFBFB url("~@assets/search.svg") no-repeat 14px center;
          padding-left: 40px;
          font-weight: bold;

          &:active, &:focus {
            color: #333333;
          }
        }
      }

      .SearchInputActive {
        height: 40px;

        input {
          font-family: "Fira_Sans_Regular", sans-serif;
          display: flex;
          align-items: center;
          max-width: 521px;
          width: 100%;
          height: 40px;
          border: 1px solid #ECECEC;
          box-sizing: border-box;
          border-radius: 6px 6px 0 0;
          font-size: 16px;
          line-height: 19px;
          color: #828282;
          margin-left: 8px;
          background: #FBFBFB url("~@assets/search.svg") no-repeat 14px center;
          padding-left: 40px;
          font-weight: bold;

          &:active, &:focus {
            color: #333333;
          }
        }
      }
    }

    .NavigationTrigger {
      display: none;

      @media only screen and (max-width: $md) {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 18px;
        color: white;
        background: transparent;
        margin-left: 30px;
        border-radius: 6px;
        padding: 12px 0 12px 18px;
        width: auto;
        
        &[data-opened = "true"] {
          .NavigationTriggerIcon {
            background: url("~@assets/close_white.svg") center center no-repeat;
          }
        }
      }

      @media only screen and (max-width: $sm) {
        margin-left: 0%;
        order: 3;
        span {
          display: none;
        }
      }

      .NavigationTriggerIcon {
        background: url("~@assets/burger.svg") center center no-repeat;
        margin-right: 5px;
        width: 30px;
        height: 30px;
        @media only screen and (max-width: $sm) {
          margin-right: 0;
        }
      }
    }

    .Navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      width: auto;
      flex: 1 auto;

      @media only screen and (max-width: $md) {
        display: none;
        &[data-opened = "true"] {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: absolute;
          width: 156px;
          background: #FFFFFF;
          border: 1px solid #ECECEC;
          box-sizing: border-box;
          /* Shadow Card */
          box-shadow: 0px 2px 30px rgba(178, 181, 194, 0.29);
          border-radius: 6px 0px 6px 6px;
          right: 40px;
          top: 72px;
          padding: 20px 0;

          .Faq, .GoodNess, .Projects, .Account .Enter {
            background: transparent;
            padding: 0;
            margin: 0;
            width: 100%;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #333333;
            margin-bottom: 7px;
            padding: 5px 24px;
            &:hover, &:active {
              background: rgba(67, 94, 237, 0.06);
              color: #124C9A;
            }
          }
          .Account {
            width: 100%;
            margin-top: 12px;
            .Enter {
              margin-bottom: 0;
              .EnterIcon {
                display: none;
              }
            }
          }
        }
      }
      
      .ButtonsGroup {
        display: flex;
        @media only screen and (max-width: $md) {
          flex-direction: column;
          width: 100%;
        }
      }

      .PersonalCabinet {
        background: transparent;
        padding: 0;
        margin: 0;
        width: 100%;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
        margin-bottom: 7px;
        padding: 5px 24px;
        display: none;

        &:hover, &:active {
          background: rgba(67, 94, 237, 0.06);
          color: #124C9A;
        }

        @media only screen and (max-width: $sm) {
          display: initial;
        }
      }

      .Faq, .GoodNess {
        margin-left: 26px;
        background: #275DAD;
        border-radius: 6px;
        padding: 12px 18px;
      }
      .Faq {
        margin-left: 50px;
      }

      a {
        text-decoration: none;
        font-size: 16px;
        line-height: 19px;
        color: #fff;
      }

      .Projects {
        margin-left: 26px;
        background: #275DAD;
        border-radius: 6px;
        padding: 12px 18px;
      }

      .Account {
        display: flex;
        align-items: center;
        position: relative;

        i {
          display: flex;
          cursor: pointer;
          width: 26px;
          height: 25px;
        }

        .Notification {
          background: url("~@assets/notification.svg") 0 0 no-repeat;
          margin-right: 10px;
          position: relative;

          @media only screen and (max-width: $sm) {
            display: none;
          }
        }

        .NotificationActive {
          position: absolute;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          top: -6px;
          right: 40px;
          background: #ED5743;

          @media only screen and (max-width: $md) {
            display: none;
          }
        }

        .User {
          background: url("~@assets/circle_user.svg") 0 0 no-repeat;
          margin-left: 10px;
          width: 24px;
          height: 24px;

          @media only screen and (max-width: $sm) {
            display: none;
          }
        }

        .Enter {
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 19px;
          color: white;
          background: transparent;

          .EnterIcon {
            background: url("~@assets/enter.svg") 0 0 no-repeat;
            margin-right: 11px;
          }
        }
      }
    }
  }
}

.SkeletonLevelItems {
  height: 48px;
  max-width: 202px;
  width: 100%;
}

.SkeletonCategoryItems {
  max-width: 174px;
  width: 100%;
  height: 20px;
  margin-bottom: 16px;
}

.ActiveLevel {
  color: #124C9A!important;
  background: rgba(67, 94, 237, 0.06);
}

.ModalContent {
  padding: 0!important;
}

.ElementData {
  font-size: 18px!important;
  line-height: 22px!important;
  padding-left: 24px!important;
  margin-bottom: 0!important;
  pointer-events: none!important;

  &:after {
    display: none!important;
  }
}

.Person {
  row-gap: 10px;
  p {
    padding-right: 40px!important;
  }

  i {
    display: none!important;
  }

  .PersonName {
    padding-left: 0!important;
  }

  img {
    margin-left: 24px!important;
    margin-right: 12px!important;
  }

  a {
    display: none!important;
  }

  & > div {
    padding: 8px 0 8px 0;

    &:hover {
      background: rgba(67, 94, 237, 0.06)!important;
    }
  }
}

.CategoriesList {
  flex-direction: column;
  align-items: initial!important;

  & > span {
    font-size: 12px;
    color: rgba(51, 51, 51, 0.5);
  }
}