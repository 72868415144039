@import "~@styles/mixins.scss";
@import "~@styles/palette.scss";
@import "~@styles/breakpoints.scss";

.Root {
  display: flex;
  padding: 30px;
  flex-direction: column;
  overflow-y: auto;
  height: 660px;
  @include Scroll;

  @media only screen and (max-width: $xs) {
    padding: 30px 0 30px 17px;
  }
}

.EmptyContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  text-align: center;
  padding: 40px;

  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
  }

  a {
    text-decoration: none;
    max-width: 166px;
    height: 36px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: #124C9A;
    border-radius: 6px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
  }
}

.Tabs {
  display: flex;
  height: 59px;
  width: 100%;
  border-bottom: 1px solid #ECECEC;

  .Tab {
    display: flex;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #333333;
    height: inherit;
    padding-left: 24px;
    padding-right: 24px;
    align-items: center;
    background: transparent;
    max-width: 151px;
    width: 100%;
    justify-content: center;
    position: relative;

    &:hover {
      color: $blue;
    }
  }

  .ActiveTab {
    color: $blue;
    background: #E8F0FF;
    border-radius: 0;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      border-bottom: 2px solid $blue;
      border-radius: initial;
      bottom: 0;
    }
  }
}
