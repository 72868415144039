@import "~@styles/breakpoints.scss";
@import "~@components/Filtration/Filtration.scss";

.Cost {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > span {
    margin-right: 11px;
  }

  @media only screen and (max-width: $xs) {
    flex-direction: column;
    align-items: flex-start
  }

  .FilterElementCostContent {
    display: flex;
    column-gap: 4px;
    @media only screen and (max-width: $xs) {
      margin: 0 0 0 0;
    }
  }

  @include filterTitle;

  .FilterElementCostIcon {
    background: url("~@assets/filter_icons/filter_coast.svg") 0 0 no-repeat;;

    @include filterElement;
    @include filterElementContainer;

    &:hover {
      background: url("~@assets/filter_icons/filter_coast_focus.svg") 0 0 no-repeat;
    }
  }

  .FilterElementCostIconActive {
    background: url("~@assets/filter_icons/filter_coast_active.svg") 0 0 no-repeat;

    &:hover {
      background: url("~@assets/filter_icons/filter_coast_active.svg") 0 0 no-repeat;
    }
  }
}
