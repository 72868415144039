@font-face {
  font-family: 'AXP_CAPTION';
  src: url('./fonts/Atlas-Caption.otf');
  src:
    url('./fonts/Atlas-Caption.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AXP_EXTRA_BOLD';
  src: url('./fonts/Atlas-Extra-Bold.otf');
  src:
          url('./fonts/Atlas-Extra-Bold.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AXP_EXTRA_BOLD_CAPS';
  src: url('./fonts/Atlas-Extra-Bold-Caps.otf');
  src:
          url('./fonts/Atlas-Extra-Bold-Caps.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AXP_ITALIC';
  src: url('./fonts/Atlas-italic.otf');
  src:
          url('./fonts/Atlas-italic.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AXP_LIGHT';
  src: url('./fonts/Light/Atlas-Light.eot');
  src:
          url('./fonts/Light/Atlas-Light.eot?#iefix') format('embedded-opentype'),
          url('./fonts/Light/Atlas-Light.woff') format('woff'),
          url('./fonts/Light/Atlas-Light.ttf') format('truetype'),
          url('./fonts/Light/Atlas-Light.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AXP_REGULAR';
  src: url('./fonts/Regular/Atlas-Regular.eot');
  src:
          url('./fonts/Regular/Atlas-Regular.eot?#iefix') format('embedded-opentype'),
          url('./fonts/Regular/Atlas-Regular.woff') format('woff'),
          url('./fonts/Regular/Atlas-Regular.ttf') format('truetype'),
          url('./fonts/Regular/Atlas-Regular.otf') format('otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AXP_BOLD';
  src: url('./fonts/Bold/AtlasBold.eot');
  src:
          url('./fonts/Bold/AtlasBold.eot?#iefix') format('embedded-opentype'),
          url('./fonts/Bold/AtlasBold.otf') format('otf'),
          url('./fonts/Bold/AtlasBold.woff') format('woff'),
          url('./fonts/Bold/AtlasBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AXP_SEMI_BOLD';
  src: url('./fonts/Atlas-Semi-Bold.otf');
  src:
          url('./fonts/Atlas-Semi-Bold.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AXP_SYMBOL';
  src: url('./fonts/Atlas-Symbol.otf');
  src:
          url('./fonts/Atlas-Symbol.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');
  src:
          url('./fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratBold';
  src: url('./fonts/Montserrat/Montserrat-Bold.ttf');
  src:
          url('./fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./fonts/Roboto/Roboto-Regular.eot');
  src: local('☺'), url('./fonts/Roboto/Roboto-Regular.woff') format('woff'), url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype'), url('./fonts/Roboto/Roboto-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('./fonts/Roboto/Roboto-Light.eot');
  src: local('☺'), url('./fonts/Roboto/Roboto-Light.woff') format('woff'), url('./fonts/Roboto/Roboto-Light.ttf') format('truetype'), url('./fonts/Roboto/Roboto-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fira_Sans_Regular';
  src: url('./fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

