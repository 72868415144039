.Root {
  border: 2px solid #E85943;
  max-width: 426px;
  width: 100%;
  height: 76px;
  background: white;
  display: flex;
  border-radius: 12px;
  align-items: center;

  & > p {
    max-width: 200px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #333333;
  }

  .DanderAlert {
    background: url("~@assets/danger_alert.svg") 0 0 no-repeat;
    width: 24px;
    height: 24px;
    margin-left: 21px;
    margin-right: 17px;
    padding: 0 10px;
  }

  .Close {
    cursor: pointer;
    background: url("~@assets/close.svg") 0 0 no-repeat;
    width: 14px;
    height: 14px;
    margin-right: 11px;
    padding: 0 10px;
  }
}
