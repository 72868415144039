@import "~@styles/palette.scss";
@import "~@styles/breakpoints.scss";

.MaterialsContainer {
  margin-bottom: 24px;
}

.FilterApplicationArea {
  display: flex;
  flex-wrap: wrap;
  margin-right: 24px;
  margin-top: 16px;

  .FilterApplicationAreaTitle {
    font-size: 16px;
    line-height: 28px;
    font-family: 'AXP_BOLD', sans-serif;
    letter-spacing: 0.02em;
    color: #333333;
    display: block;
    margin-bottom: 4px;
  }

  .FilterApplicationAreaRoot {
    display: block;
  }
}

.MaterialsColorsContainer {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.Root {
  display: flex;
  flex-wrap: wrap;
  column-gap: 27px;
  row-gap: 12px;
}

.ContainerImg {
  display: flex;
  max-width: 230px;
  width: 100%;
  align-items: center;

  span {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: $gray1;
    padding-left: 13px;
  }

  img {
    max-width: 84px;
    width: 100%;
    height: 84px;
  }
}

.Content {
  display: flex;
  width: 100%;
  margin-top: 60px;

  @media only screen and (max-width: $sm) {
    flex-direction: column;
  }

  &:first-child {
    margin-top: 0;
  }

  .ContentImg {
    max-width: 375px;
    width: 100%;
    height: 376px;

    @media only screen and (max-width: $sm) {
      max-width: initial;
      height: 100%;
      object-fit: contain;
      margin-bottom: 16px;
    }
  }

  .Description {
    margin-left: 24px;
    margin-right: 90px;

    @media only screen and (max-width: $sm) {
      margin: initial;
    }

    .FullDescMobile {
      display: none;

      @media only screen and (max-width: $sm) {
        display: flex;
        flex-direction: column;

        & p {
          margin: initial;
        }
      }
    }

    h4 {
      font-weight: bold;
      font-size: 28px;
      line-height: 28px;
      letter-spacing: -0.0125em;
      color: #333333;
    }

    .DescriptionSubDesc {
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      margin: 0;
      padding-top: 8px;
      max-width: 600px;
      width: 100%;
    }

    .Specifications {
      display: flex;
      align-items: baseline;

      .FilterComplexityInstallation {
        margin-top: 48px;

        .FilterComplexityInstallationTitle {
          font-size: 16px;
          line-height: 28px;
          font-family: 'AXP_BOLD', sans-serif;
          letter-spacing: 0.02em;
          color: #333333;
          display: block;
          margin-bottom: 4px;
        }

        .FilterComplexityInstallationRoot {
          display: block;
        }
      }

      .FilterCost {

        .FilterCostTitle {
          font-size: 16px;
          line-height: 28px;
          font-family: 'AXP_BOLD', sans-serif;
          letter-spacing: 0.02em;
          color: #333333;
          display: block;
          margin-top: 16px;
        }

        .FilterCostRoot {
          display: block;
        }
      }

      .Column {
        margin-right: 50px;

        @media only screen and (max-width: $sm) {
          margin: initial;
        }

        & > p > strong {
          font-weight: bold;
          font-size: 18px;
          line-height: 20px;
          color: #333333;
          letter-spacing: -0.0124em;
          margin: 0;
        }

        p {
          font-size: 18px;
          line-height: 20px;
          color: #333333;
          letter-spacing: -0.0124em;
          margin: initial;

          @media only screen and (max-width: $sm) {
            display: none;
          }
        }

        .Quality {
          font-size: 18px;
          line-height: 20px;
          color: #333333;
          letter-spacing: -0.0124em;
          padding-bottom: 28px;
          display: block;
        }
      }
    }
  }

  .ExampleImgs {

    .ExampleImgsContainer {
      display: flex;
      align-items: center;

      .ExampleImg {
        max-width: 84px;
        width: 100%;
        height: 84px;
        margin-bottom: 12px;
      }

      span {
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: $gray1;
        padding-left: 13px;
      }
    }
  }
}
