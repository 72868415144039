.Root {
  margin: 120px auto 0;
  max-width: 1360px;
  width: 100%;
  padding: 0 40px 40px 40px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  & > h4 {
    font-size: 20px;
  }

  & > ul {
    li {
      list-style: none;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    row-gap: 10px;

    img {
      max-width: 100%;
    }
  }

  & > p {
    img {
      max-width: 100%;
    }
  }
}
