@import "~@views/PrivateCabinet/components/PersonalData/PersonalData";

.ChangePasswordForm {

  .ChangePasswordFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ChangePasswordFormField {
      display: flex;
      flex-direction: column;
      max-width: 268px;
      width: 100%;
      position: relative;
      margin-bottom: 16px;

      .ChangePasswordFormFieldContainer {
        position: relative;

        svg {
          position: absolute;
          right: 12px;
          top: 16px;
          cursor: pointer;
        }
      }

      label {
        @include Label;
        font-family: AXP_LIGHT, sans-serif;
      }

      input {
        @include Input;
        background: #FFFFFF;
        font-weight: 600;
        margin-bottom: 16px;
        height: 36px;
        font-size: 24px;
      }

      span {
        position: absolute;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        font-family: 'AXP_LIGHT', sans-serif;
        color: #828282;
        bottom: -20px;
        right: 0;
        cursor: pointer;
      }
    }
  }

  .ChangePasswordFormFieldBtn {
    @include SubmitButton;
  }
}

.SuccessChangePsw {
  display: flex;
  max-width: 227px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;

  svg {
    position: relative;
    right: initial;
    top: initial;
    margin-bottom: 15px;
  }

  span {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    font-family: 'AXP_LIGHT', sans-serif;
    letter-spacing: -0.0125em;
    text-align: center;
    color: #333333;
    margin-bottom: 62px;
  }
}
