.Root {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  z-index: 1;

  &Close {
    background: url("~@assets/close.svg") 0 0 no-repeat;
    position: absolute;
    top: 18px;
    right: 18px;
    width: 13px;
    height: 13px;
    cursor: pointer;
  }

  &Container {
    padding: 30px;
    max-width: 387px;
    width: 100%;
    height: 287px;
    box-shadow: 0 2px 30px rgba(178, 181, 194, 0.29);
    border-radius: 6px;
    background: #fff;
    position: fixed;
    right: 0;
    left: 0;
    margin: 0 auto;
    top: 140px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;

    &Title {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.0125em;
      color: #333333;
      font-family: 'AXP_BOLD', sans-serif;
    }

    & > svg {
      margin-top: 40px;
    }

    &Description {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.0125em;
      color: #333333;
      margin-top: 20px;
      font-family: 'AXP_BOLD', sans-serif;
    }
  }
}
