$xs: 480px;
$sm: 768px;
$md: 1121px;
$lg: 1366px;
$xl: 1440px;

:export {
  XS: $xs;
  SM: $sm;
  MD: $md;
  LG: $lg;
  XL: $xl;
}
