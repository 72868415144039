@import "~@styles/breakpoints.scss";

.Content {
  margin-top: 30px;
  margin-bottom: 114px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
  @media only screen and (max-width: $xl) {
    gap: 1vw;
  }
  @media only screen and (max-width: $sm) {
    gap: 2vw;
  }
  @media only screen and (max-width: $xs) {
    gap: unset;
  }

  span {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    @media only screen and (max-width: $xl) {
      gap: 1vw;
    }
    @media only screen and (max-width: $sm) {
      gap: 2vw;
    }
    @media only screen and (max-width: $xs) {
      gap: unset;
    }
  }

  &Loader {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    transform: scale(2);
  }
}
