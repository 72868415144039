@import "~@styles/breakpoints.scss";

.CardContainer {
  position: relative;
  max-width: 243px;
  width: 100%;

  .Card {
    width: 100%;
    height: 356px;
    background: #FFFFFF;
    border: 0.5px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 6px;
    margin-right: 40px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    display: block;
    @media only screen and (max-width: $md) {
      height: 320px;
    }

    .CardPlus {
      background: url("~@assets/plus.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      display: inline;
      position: absolute;
      top: 12px;
      right: 12px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      @media only screen and (max-width: $md) {
        width: 30px;
        height: 30px;
      }
    }

    .CardClose {
      background: url("~@assets/modal_close.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      display: inline;
      position: absolute;
      top: 12px;
      right: 12px;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }

    img {
      border-radius: 6px 6px 0 0;
      max-width: 243px;
      width: 100%;
      height: 210px;
      object-fit: cover;
      @media only screen and (max-width: $md) {
        height: 150px;
      }
    }

    .CardIcons {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding-right: 8.5px;
      position: absolute;
      bottom: 12px;

      .CardIconsTitle {
        display: none;
      }

      div {
        div:last-of-type {
          margin-right: 0;
          flex-wrap: wrap;
          justify-content: flex-end;
          div {
            span {
              @media only screen and (max-width: $lg) {
                width: 20px;
                height: 20px;
                background-size: contain;
              }
            }
          }
        }

        
      }
    }

    h5 {
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: #333333;
      padding-top: 12px;
      padding-bottom: 2px;
      max-width: 154px;
      width: 100%;
      padding-left: 13px;
    }

    p {
      padding-top: 2px;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      margin: initial;
      max-width: 209px;
      width: 100%;
      padding-left: 13px;
    }
  }
}
