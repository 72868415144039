@import '~@styles/mixins.scss';

.Root {
  @include ContainerLg;
  margin-top: 120px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 120px;

  h1 {
    font-family: 'AXP_REGULAR', sans-serif;
    font-size: 36px;
    line-height: 30px;
  }

  h2 {
    font-family: 'AXP_REGULAR', sans-serif;
    font-size: 26px;
    padding-top: 10px;
    line-height: 26px;
  }

  p {
    font-family: 'AXP_REGULAR', sans-serif;
    font-size: 18px;
  }
}
