@import "~@views/PrivateCabinet/components/PersonalData/PersonalData";

.MainInfo {
  border-bottom: 1px solid #EDEDED;
  padding: 30px;

  .Form {

    .FormTitle {
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      padding-bottom: 30px;
      font-family: AXP_BOLD, sans-serif;
    }

    .FormContainer {

      .FormContainerField {
        display: flex;
        flex-direction: column;

        label {
          @include Label;
          font-family: 'AXP_LIGHT', sans-serif;
        }

        .FormInputContainer {
          display: flex;
          height: 36px;
          margin-bottom: 30px;

          .InputContent {
            max-width: 221px;
            width: 100%;
            margin-right: 12px;

            input {
              max-width: 221px;
              @include Input;
              font-family: 'AXP_LIGHT', sans-serif;

              &::placeholder {
                font-family: 'AXP_REGULAR', sans-serif;
                font-weight: 600;
                color: #333333;
              }
            }
          }

          .FormInputContainerButton {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #4F4F4F;
            max-width: 58px;
            width: 100%;
            background: transparent;
          }
        }
      }
    }
  }
}
