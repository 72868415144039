$blue: #124C9A;
$red: #E85943;
$green: #1CAB88;
$gray1: #333333;
$gray2: #4F4F4F;
$gray3: #828282;
$gray4: #BDBDBD;
$gray6: #F2F2F2;
$blueMain: #124C9A;
$accentRed: #E85943;
