.Root {
  max-width: 883px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 6px;
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 2;

  svg {
    cursor: pointer;
    position: absolute;
    top: 22px;
    right: 22px;
    width: 12px;
    height: 12px;

    path {
      fill: #C4C4C4;
    }
  }

  .TableContainer {
    padding: 26px;

    header {
      display: flex;
    }

    .TableRow {
      display: flex;
      flex-flow: row wrap;

      div {
        font-weight: initial;
      }
    }

    .TableLastRow {
      display: flex;
      flex-flow: row wrap;
      border-top: 1px solid #ECECEC;

      div {
        font-weight: initial;
      }
    }

    .TableColumn {
      font-size: 16px;
      line-height: 49px;
      letter-spacing: -0.0124em;
      color: #333333;
      flex: 1;
      font-weight: bold;
      display: flex;
      justify-content: center;
    }

    .TableColumnTitle {
      font-size: 16px;
      line-height: 49px;
      letter-spacing: -0.0124em;
      color: #333333;
      font-weight: bold!important;
      display: flex;
      justify-content: start;
    }
  }
}
