@import "~@styles/breakpoints.scss";
@import '~@styles/palette.scss';

.DesignPageTabs {
  display: flex;

  .DesignPageTabsTab {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    width: initial;
    background: transparent;
    border-radius: 0;
    height: 54px;
    padding: 0;
    @media only screen and (max-width: $xs) {
      display: none;
      height: auto;
      &:after {
        content: "";
        background: url("~@assets/arrow_down.svg") center center no-repeat;
        background-size: contain;
        display: block;
        width: 12px;
        height: 12px;
        transform: rotate(0) translate(10px, 0);
      }

      &[data-checked = "true"] {
        &:after {
          transform: rotate(180deg) translate(-10px, 2px);
        }
      }
    }

    &:hover {
      color: $blue;
    }

    &:nth-child(2) {
      margin-left: 92px;
      @media only screen and (max-width: $xs) {
        margin-left: 0;
      }
    }
  }

  .DesignPageTabsTabActive {
    border-bottom: 2px solid $blue;
    color: $blue;
    @media only screen and (max-width: $xs) {
      display: flex;
      width: 100%;
      align-items: center;
    }
  }
}

.DesignPageSelector {
  display: none;
  @media only screen and (max-width: $xs) {
    position: absolute;
    flex-direction: column;
    width: 100%;
    left: 0;
    margin-top: 25px;
    &[data-show = "true"] {
      display: block;
    }
  }

  &Container {
    @media only screen and (max-width: $xs) {
      position: relative;
      flex-direction: column;
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 2px 30px rgba(178, 181, 194, 0.29);
      border-radius: 0 0 10px 10px;
      padding: 8px 0;
    }
  }
  &Item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding: 8px 16px;
    margin: 12px 0;

    &:active, &:hover {
      background: rgba(67, 94, 237, 0.06);
    }

    &[data-selected = "true"] {
      color: #124C9A;
      &::after {
        content: "";
        background: url("~@assets/check_mark_blue.svg") center center no-repeat;
        background-size: contain;
        display: block;
        width: 10px;
        height: 10px;
      }
    }
  }
}
