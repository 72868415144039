.Root {
  width: 100%;
  background: #FFFFFF;
  position: absolute;
  top: 90px;
  box-shadow: 0 2px 30px rgba(178, 181, 194, 0.29);
  border-radius: 6px;
  padding-top: 30px;
  z-index: 2;

  .CloseIcon {
    position: absolute;
    right: 18px;
    top: 18px;
    cursor: pointer;
  }

  h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.0125em;
    color: #333333;
    text-align: center;
  }

  .ModalContent {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 40px;
  }
}
