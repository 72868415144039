@import "~@styles/breakpoints.scss";

.ElementDate {
  font-size: 24px;
  line-height: 29px;
  font-family: 'AXP_BOLD', sans-serif;
  color: #000000;
  margin-bottom: 16px;
  position: relative;
  margin-top: 40px;

  &:first-child {
    margin-top: 0;
  }
}

.ActiveElementDate {
  &:after {
    content: "";
    position: absolute;
    background: url("~@assets/check_mark.svg") 0 0 no-repeat;
    width: 13px;
    height: 8px;
    margin-left: 8px;
    margin-top: 7px;
    transform: rotate(180deg);
  }
}

.Person {
  display: flex;
  padding-bottom: 12px;
  padding-top: 12px;
  flex-direction: column;
  row-gap: 24px;

  .PersonContainer {
    display: flex;
    align-items: center;

    &Content {
      display: flex;

      @media only screen and (max-width: $xs) {
        flex-direction: column;
      }
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    p {
      padding: initial;
      margin: 6px 0 0 0;

      @media only screen and (max-width: $md) {
        padding-left: 6px;
      }
    }

    .PersonName {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.0125em;
      color: #333333;
      padding-left: 12px;
      padding-right: 8px;

      @media only screen and (max-width: $md) {
        padding-left: 0;
      }
    }

    .Action {
      position: relative;

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.0125em;
        color: #828282;
        padding-right: 16px;
        font-family: 'AXP_LIGHT', sans-serif;
      }

      i {
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #ED5743;
        top: -11px;
        right: 18px;
      }
    }
  }

  a {
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #4F4F4F;
    margin: 6px 0 0 0;

    @media only screen and (max-width: $xs) {
      padding-left: 6px;
    }

    &:hover, &:active {
      color: #124C9A;
    }
  }
}
