@import "~@styles/breakpoints.scss";
@import "~@styles/palette.scss";

.RegistrationForm {
  max-width: 387px;
  width: 100%;
  height: auto;
  box-shadow: 0 2px 30px rgba(178, 181, 194, 0.29);
  border-radius: 6px;
  background: #fff;
  position: fixed;
  right: 0;
  left: 0;
  margin: 0 auto;
  top: 140px;
  z-index: 4;

  @media only screen and (max-width: $sm) {
    height: 100vh;
    top: 80px;
    overflow-y: auto;
    max-width: initial;
  }

  &Title {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.0125em;
    color: #000000;
  }

  &Container {
    max-width: 268px;
    width: 100%;
    margin: 0 auto;

    &Field {
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      label {
        color: #828282;
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 8px;
      }

      input {
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        box-sizing: border-box;
        border-radius: 6px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.0125em;
        color: #333333;
        padding-top: 7px;
        padding-bottom: 5px;
        padding-left: 12px;
        position: relative;

        &::placeholder {
          color: #BDBDBD;
        }
      }

      &Psw {
        position: relative;
        width: 100%;

        input {
          width: 100%;
          letter-spacing: -0.0125em;
        }

        i {
          &[data-show="false"] {
            background: url("~@assets/show_password.svg") 0 0 no-repeat;
            top: 6px;
            right: 9px;
            position: absolute;
            width: 20px;
            cursor: pointer;
            height: 20px;
          }

          &[data-show="true"] {
            background: url("~@assets/show_psw.svg") 0 0 no-repeat;
            position: absolute;
            width: 20px;
            height: 8px;
            right: 7px;
            top: 16px;
            cursor: pointer;
          }
        }

        &Signature {
          font-size: 12px;
          line-height: 20px;
          color: #828282;
          text-align: right;
          margin-top: 2px;
        }
      }
    }
  }

  &Actions {
    max-width: 268px;
    width: 100%;
    margin: 40px auto 50px;

    &Socials {
      display: flex;
      column-gap: 7px;
      height: 36px;
      width: 100%;
      justify-content: center;
      margin-top: 15px;

      @media only screen and (max-width: $sm) {
        margin-bottom: 120px;
      }

      a, i {
        display: block;
        width: 36px;
        height: 36px;
        cursor: pointer;
      }

      &Google {
        background: url("~@assets/google_mail.svg") 0 0 no-repeat;
      }

      &Fb {
        background: url("~@assets/facebook_mail.svg") 0 0 no-repeat;
      }

      &Vk {
        background: url("~@assets/vk_mail.svg") 0 0 no-repeat;
      }
    }

    button {
      background: #124C9A;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      font-family: 'AXP_BOLD', sans-serif;
      max-width: 210px;
      height: 36px;
      margin: 0 auto;
      display: block;
    }

    &HasAccount {
      font-size: 14px;
      line-height: 20px;
      color: #828282;
      margin-top: 8px;
      text-align: center;
      cursor: pointer;
    }
  }
}

.SuccessRegistration {
  max-width: 387px;
  height: 335px;
  background: #fff;
  box-shadow: 0 2px 30px rgba(178, 181, 194, 0.29);
  border-radius: 6px;
  position: fixed;
  right: 0;
  left: 0;
  margin: 0 auto;
  top: 140px;
  z-index: 1;

  &Submit {
    background: #1CAB88;
    border-radius: 6px;
    max-width: 169px;
    width: 100%;
    margin: 30px auto 0;
    height: 36px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    display: block;

    &:disabled {
      background: #E0E0E0;
      cursor: initial;
    }
  }

  &CodeContainer {
    width: 200px;
    margin: 0 auto;

    &Code {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #BDBDBD;
      white-space: nowrap;
      margin-top: 8px;
      display: block;
      text-align: center;
    }

    & > p {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: $gray3;
      padding: initial;
      margin: initial;
    }

    &Btn {
      background: transparent;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: $blueMain;
      margin-top: 8px;
    }

    &Inputs {
      display: flex;
      margin-top: 8px;

      &Errors {
        & > div > div > input {
          border: 1px solid #E85943!important;
        }
      }

      & > div > div {
        display: flex!important;
        column-gap: 5px;

        input {
          background: #FFFFFF;
          border: 1px solid #EDEDED!important;
          width: 36px!important;
          height: 40px!important;
          border-radius: 6px;
          box-shadow: none!important;
          padding: initial!important;
          margin: initial!important;
          text-align: center;
          font-family: 'AXP_REGULAR', sans-serif;
        }
      }
    }
  }

  &Container {
    max-width: 338px;
    width: 100%;
    margin: 30px auto 0;

    &ErrorMsg {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #E85943;
      margin: 12px auto 30px;
      display: block;
      text-align: center;
    }

    &Title {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: -0.0125em;
      color: #333333;
      font-family: 'AXP_BOLD', sans-serif;
    }

    &Msg {
      text-align: center;
      letter-spacing: -0.0125em;
      color: #333333;
      font-weight: bold;
      margin-top: 40px;
    }

    &SubMsg {
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: -0.0125em;
      color: #333333;
      max-width: 254px;
      width: 100%;
      margin: 0 auto;
      padding-top: 8px;
      padding-bottom: 30px;

      span {
        color: #124c9a;
      }
    }
  }
}

.Close {
  background: url("~@assets/close.svg") 0 0 no-repeat;
  position: absolute;
  top: 18px;
  right: 18px;
  width: 13px;
  height: 13px;
  cursor: pointer;
}

.Form {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  z-index: 1;
}

.RegistrationFormInputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  span {
    color: #E85943;
    bottom: -20px;
  }
}

.RegistrationFormInputError {
  input {
    border: 1px solid #E85943!important;
  }
}

.RegistrationFormContainerFieldCheckBox {
  span {
    &::before {
      top: 12px!important;
    }
  }
}
