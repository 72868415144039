@mixin Label {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #828282;
  padding-bottom: 8px;
}

@mixin Input {
  width: 100%;
  height: inherit;
  background: #F2F2F2;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.0125em;
  color: #333333;
  padding-left: 12px;
}

@mixin SubmitButton {
  font-family: 'AXP_LIGHT', sans-serif;
  background: #1CAB88;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  max-width: 148px;
  width: 100%;
  margin: 22px auto 62px;
  height: 36px;
}
