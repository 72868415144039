@import "~@styles/mixins.scss";

.Root {
  padding: 30px;
  display: flex;
  height: 660px;
  @include Scroll;

  & > div {
    width: 100%;
  }
}
