$gray: #828282;

.Root {
  display: flex;
  align-items: center;
  margin-top: 31px;

  .BreadCrumb {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: $gray;
    text-decoration: none;
  }

  .BreadCrumbActive {
    color: #4F4F4F;
    letter-spacing: -0.0125em;
  }

  svg {
    transform: rotate(-90deg);
    margin-top: -4px;
    margin-left: 12px;
    margin-right: 12px;
    width: 8px;

    path {
      stroke: $gray;
    }
  }
}
