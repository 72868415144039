@import "~@styles/breakpoints.scss";
@import "~@styles/mixins.scss";


.Root {
  background: #F7F7F7;
  margin: 0 auto;
  align-items: center;

  a {
    font-size: 18px;
    line-height: 24px;
    color: #4F4F4F;
    text-decoration: none;
    @media only screen and (max-width: $md) {
      font-size: 16px;
      line-height: 18px;
    }
    @media only screen and (max-width: $sm) {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .Container {
    @include ContainerLg;
    display: flex;
    flex-wrap: wrap;
    column-gap: 32px;
    padding: 24px 40px 0 40px;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: $lg) {
      padding: 18px 40px 0 40px;
    }
    @media only screen and (max-width: $xs) {
      padding: 0 20px 0 20px;
    }

    .Navigation {
      display: flex;
      align-items: center;

      ul {
        display: flex;
        flex-wrap: wrap;

        @media only screen and (max-width: $xs) {
          margin: 25px 0 0 0;
          flex-direction: column;
          row-gap: 10px;
          margin-bottom: 10px;
        }

        li {

          &:last-child {
            margin-right: 0;
          }

          list-style: none;
          margin-right: 32px;
        }
      }
    }

    .Text {
      display: flex;
      justify-content: flex-start;
      text-align: left;
      max-width: 169px;
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      color: #828282;
      @media only screen and (max-width: $md) {
        font-size: 16px;
        line-height: 18px;
        align-items: center;
      }
    }

    h6 {
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      @media only screen and (max-width: $md) {
        font-size: 16px;
        line-height: 18px;
      }
    }

    .Contacts {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      font-style: normal;
      width: 31%;
      @media only screen and (max-width: $lg) {
        margin-top: 24px;
        width: 32.4%;
      }
      @media only screen and (max-width: $md) {
        margin-top: 5px;
        width: 35.5%;
      }
    }

    .Social {
      display: flex;
      text-align: right;
      font-size: 18px;
      line-height: 24px;
      color: #828282;
      align-items: center;

      @media only screen and (max-width: $sm) {
        margin-top: 11px;
      }
      @media only screen and (max-width: $xs) {
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-top: 19px;
        margin-left: 10px;
      }

      &Links {
        display: flex;
        justify-content: flex-end;
      }

      a {
        display: block;
        width: 25px;
        height: 25px;
        margin: 4px 0 0 15px;
        background-size: contain;
        @media only screen and (max-width: $md) {
          margin: 4px 0 0 10px;
        }
      }

      &Vk {
        background: url("~@assets/social_vk.svg") no-repeat center center;
        order: 1;
        border: none;
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
      &Fb {
        background: url("~@assets/social_fb.svg") no-repeat center center;
        order: 2;
      }
      &Ok {
        background: url("~@assets/social_ok.svg") no-repeat center center;
        order: 3;
      }
    }

    .Additional {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      width: 50%;
      padding: 0 8% 0 0;
      margin: 24px 0 0 0;
      @media only screen and (max-width: $md) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .Partners {
    display: flex;
    border: none;
    text-decoration: none;
    align-items: center;
    padding: 10px 0 0 0;

    &Atlas, &Mosjil, &DKR {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top left;
      margin-left: 10px;
      pointer-events: none;
    }

    &Atlas {
      width: 96px;
      height: 33px;
      background-image: url("~@assets/partners/atlas.svg");
    }
    &Mosjil {
      width: 100px;
      height: 52px;
      background-image: url("~@assets/partners/mosjil.svg");
    }
    &DKR {
      width: 128px;
      height: 58px;
      background-image: url("~@assets/partners/kghm.png");
    }
  }

  .ProjectName {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 40px 20px 40px;
    margin: 16px auto 0;
    border-top: 1px solid #EDEDED;
    max-width: 1360px;
    @media only screen and (max-width: $xs) {
      padding: 0 20px 0 20px;
    }
    span {
      font-size: 16px;
      line-height: 24px;
      color: #828282;
      @media only screen and (max-width: $md) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    a {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      text-decoration-line: underline;
      @media only screen and (max-width: $md) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

.SocialsNetworks {
  display: flex;
  column-gap: 36px;

  @media only screen and (max-width: $md) {
    width: 100%;
    margin-top: 30px;
    margin-left: -10px;
    flex-wrap: wrap;
  }
}

.SubFooterRightLinks {
  display: flex;
  column-gap: 20px;

  @media only screen and (max-width: $sm) {
    flex-direction: column;

    & > a {
      white-space: nowrap;
    }
  }
}
