.Root {
  width: 100%;

  .EmptyContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;

    .IconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 99px;
      height: 99px;
      border-radius: 50%;
      background: #F2F2F2;
    }

    p {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.0125em;
      color: #333333;
    }
  }
}

.ShowAllBtn {
  font-family: 'AXP_LIGHT', sans-serif;
  color: #828282;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 24px;
}
