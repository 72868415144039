@import "~@styles/breakpoints.scss";
@import '~@styles/palette.scss';

.Root {
  max-width: 673px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  width: 631px;
  height: 432px;
  flex: 1 auto;
  @media only screen and (max-width: $xl) {
    width: 45.9vw;
    height: 30vw;
  }
  @media only screen and (max-width: $md) {
    width: 44.3vw;
    height: 40vw;
  }
  @media only screen and (max-width: $sm) {
    width: 100%;
    height: 58.34vw;
    max-width: unset;
  }
  @media only screen and (max-width: $xs) {
    height: auto;
  }

  p {
    @media only screen and (max-width: $xs) {
      height: auto !important;
      font-style: normal !important;
      font-weight: bold !important;
      font-size: 14px !important;
      line-height: 18px !important;
      padding: 9px 36px !important;
    }
  }


  .VariantFirst {
    display: flex;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
    }

    p {
      font-weight: 600;
      font-size: 24px;
      line-height: 23px;
      color: #FFFFFF;
      height: auto;
      padding: 9px 20px;
      min-height: 48px;
      background: $accentRed;
      left: 0;
      right: 0;
      position: absolute;
      bottom: 0;
      margin: 0;
      display: flex;
      align-items: center;
      @media only screen and (max-width: $xl) {
        font-style: normal;
        font-weight: 600;
        font-size: 2.14vw;
        line-height: 2.8vw;
        height: auto;
        padding: 9px 20px;
      }
      @media only screen and (max-width: $sm) {
        padding: 9px 36px;
        min-height: 38px;
      }
    }

    &:nth-child(1) {
      width: 100%;
      height: 204px;
      @media only screen and (max-width: $xl) {
        height: 14.6vw;
      }
      @media only screen and (max-width: $md) {
        height: 19.6vw;
      }
      @media only screen and (max-width: $sm) {
        height: 28.2vw;
      }
      @media only screen and (max-width: $xs) {
        height: 42.8vw;
        min-height: 204px;
      }
    }

    &:nth-child(2) {
      max-width: 322px;
      width: 49%;
      height: 204px;
      @media only screen and (max-width: $xl) {
        max-width: unset;
        height: 14.6vw;
      }
      @media only screen and (max-width: $md) {
        height: 19.6vw;
      }
      @media only screen and (max-width: $sm) {
        height: 28.2vw;
      }
      @media only screen and (max-width: $xs) {
        width: 100%;
        height: 42.8vw;
        min-height: 204px;
      }

      p {
        font-size: 18px;
        line-height: 23px;
        background: $blueMain;
        @media only screen and (max-width: $sm) {
          font-style: normal;
          font-weight: bold;
          font-size: 2.4vw;
        }
      }
    }

    &:nth-child(3) {
      max-width: 322px;
      width: 49%;
      height: 204px;
      @media only screen and (max-width: $xl) {
        max-width: unset;
        height: 14.6vw;
      }
      @media only screen and (max-width: $md) {
        height: 19.6vw;
      }
      @media only screen and (max-width: $sm) {
        height: 28.2vw;
      }
      @media only screen and (max-width: $xs) {
        width: 100%;
        height: 42.8vw;
        min-height: 204px;
      }

      p {
        font-size: 18px;
        line-height: 23px;
        background: $green;
        @media only screen and (max-width: $sm) {
          font-style: normal;
          font-weight: bold;
          font-size: 2.4vw;
        }
      }
    }
  }

  .VariantSecond {
    background: #1CAB88;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    @media only screen and (max-width: $xs) {
      width: 100%;
      height: 42.8vw;
      min-height: 204px;
    }

    img {
      width: 100%;
      height: 100%;
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
    }

    p {
      position: absolute;
      background: $blueMain;
      font-weight: 600;
      font-size: 26px;
      line-height: 23px;
      color: #FFFFFF;
      min-height: 48px;
      height: auto;
      padding: 9px 20px;
      bottom: 0;
      margin: 0;
      display: flex;
      align-items: center;
      left: 0;
      right: 0;
      @media only screen and (max-width: $xl) {
        font-style: normal;
        font-weight: 600;
        font-size: 2.14vw;
        line-height: 2.8vw;

      }
      @media only screen and (max-width: $sm) {
        padding: 9px 36px;
        min-height: 38px;
      }
    }
  }

  .VariantThird {
    display: flex;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
    }

    p {
      font-weight: 600;
      font-size: 24px;
      line-height: 23px;
      color: #FFFFFF;
      height: auto;
      padding: 9px 20px;
      min-height: 48px;
      background: $accentRed;
      left: 0;
      right: 0;
      position: absolute;
      bottom: 0;
      margin: 0;
      display: flex;
      align-items: center;
      @media only screen and (max-width: $xl) {
        font-style: normal;
        font-weight: 600;
        font-size: 2.14vw;
        line-height: 2.8vw;
        height: auto;
        padding: 9px 20px;
      }
      @media only screen and (max-width: $sm) {
        padding: 9px 36px;
        min-height: 38px;
      }
    }

    &:nth-child(1) {
      max-width: 322px;
      width: 49%;
      height: 204px;
      @media only screen and (max-width: $xl) {
        max-width: unset;
        height: 14.6vw;
      }
      @media only screen and (max-width: $md) {
        height: 19.6vw;
      }
      @media only screen and (max-width: $sm) {
        height: 28.2vw;
      }
      @media only screen and (max-width: $xs) {
        width: 100%;
        height: 42.8vw;
        min-height: 204px;
      }

      p {
        font-size: 18px;
        line-height: 23px;
        background: $green;
        @media only screen and (max-width: $sm) {
          font-style: normal;
          font-weight: bold;
          font-size: 2.4vw;
        }
      }
    }

    &:nth-child(2) {
      max-width: 322px;
      width: 49%;
      height: 204px;
      @media only screen and (max-width: $xl) {
        max-width: unset;
        height: 14.6vw;
      }
      @media only screen and (max-width: $md) {
        height: 19.6vw;
      }
      @media only screen and (max-width: $sm) {
        height: 28.2vw;
      }
      @media only screen and (max-width: $xs) {
        width: 100%;
        height: 42.8vw;
        min-height: 204px;
      }

      p {
        font-size: 18px;
        line-height: 23px;
        background: $blueMain;
        @media only screen and (max-width: $sm) {
          font-style: normal;
          font-weight: bold;
          font-size: 2.4vw;
        }
      }
    }

    &:nth-child(3) {
      width: 100%;
      height: 204px;
      @media only screen and (max-width: $xl) {
        height: 14.6vw;
      }
      @media only screen and (max-width: $md) {
        height: 19.6vw;
      }
      @media only screen and (max-width: $sm) {
        height: 28.2vw;
      }
      @media only screen and (max-width: $xs) {
        width: 100%;
        height: 42.8vw;
        min-height: 204px;
      }
    }
  }
}
