@import "~@styles/breakpoints.scss";
@import "~@styles/mixins.scss";
@import '~@styles/palette.scss';

.Root {
  height: 100%;

  .Tabs {
    display: flex;
    height: 59px;
    width: 100%;
    border-bottom: 1px solid #ECECEC;

    @media only screen and (max-width: $xs) {
      justify-content: space-between;
    }

    .Tab {
      display: flex;
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
      color: #333333;
      height: inherit;
      align-items: center;
      background: transparent;
      max-width: 151px;
      width: 100%;
      justify-content: center;
      position: relative;
      @media only screen and (max-width: $md) {
        font-size: 1.5vw;
        line-height: 1.5vw;
      }
      @media only screen and (max-width: $sm) {
        font-size: 2.3vw;
        line-height: 2.5vw;
      }
      @media only screen and (max-width: $xs) {
        font-size: 16px;
        line-height: 28px;
        max-width: 100%;
      }

      &:hover {
        color: $blue;
      }
    }

    .ActiveTab {
      color: $blue;
      background: #E8F0FF;
      border-radius: 0;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        border-bottom: 2px solid $blue;
        border-radius: initial;
        bottom: 0;
      }
    }
  }
}
