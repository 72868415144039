.CustomCheckbox {
  & > span {
    display: inline-flex;
    align-items: center;
    user-select: none;
    position: relative;

    &::after {
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: 2px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
      border: 2px solid #BDBDBD;
      margin-left: 13px;
      margin-top: -4px;
    }
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  input:disabled + span {
    display: none;
  }

  &Error {
    &::after {
      border: 2px solid #E85943!important;
    }
  }

  /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
  //& > input:not(:disabled):active + span::after {
  //  background-color: #b3d7ff;
  //  border-color: #b3d7ff;
  //}

  /* стили для чекбокса, находящегося в состоянии checked */
  & > input:checked + span:before {
    content: "";
    background: url("~@assets/check_mark_blue.svg") 0 0 no-repeat;
    width: 15px;
    height: 12px;
    position: absolute;
    right: -3px;
    top: 0;
  }

  a {
    text-decoration: none;
  }

  /* стили для чекбокса, находящегося в состоянии disabled */
  & > input:disabled + span::after {
    border: 2px solid #BDBDBD;
  }
}
