@import '~@styles/mixins.scss';

.CardModalWindow {
  width: 320px;
  height: 300px;
  position: absolute;
  top: 66px;
  left: -70px;
  background: #fff;
  z-index: 1;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  box-shadow: 0 2px 30px rgba(178, 181, 194, 0.29);
  border-radius: 6px;

  .CardModalWindowTabs {
    height: 41px;
    border-bottom: 1px solid #ECECEC;
    display: flex;

    .CardModalWindowTabsElementActive {
      color: #124C9A !important;
      &:after {
        content: "";
        position: absolute;
        border-bottom: 2px solid #124C9A;
        max-width: 159px;
        width: 100%;
        bottom: 0;
      }
    }

    .CardModalWindowTabsElement {
      font-size: 14px;
      line-height: 28px;
      font-weight: bold;
      color: #333333;
      max-width: 159px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;

      &:hover {
        color: #124C9A;
      }
    }
  }

  .CardModalWindowTitle {
    height: 58px;
    border-bottom: 1px solid #ECECEC;
    display: flex;
    align-items: center;
    padding-left: 28px;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    font-weight: bold;
    cursor: pointer;
    position: relative;

    &Container {
      display: flex;
      align-items: center;
      column-gap: 12px;
    }

    & > i {
      position: absolute;
      background: url("~@assets/sharing.svg") center no-repeat;
      width: 26px;
      height: 26px;
      right: 26px;

      &[data-title="Ссылка скопирована"]:hover:after {
        opacity: 1;
        transition: all 0.1s ease 0.5s;
        visibility: visible;
      }

      &[data-title="Ссылка скопирована"]:after {
        content: attr(data-title);
        background: #F2F2F2;
        border-radius: 11px;
        color: #333333;
        font-size: 16px;
        line-height: 14px;
        position: absolute;
        padding: 6px 9px 5px 10px;
        bottom: -1.6em;
        white-space: nowrap;
        opacity: 0;
        z-index: 99999;
        visibility: hidden;
        right: 0;
      }
    }

    span {
      margin-top: 4px;
      max-width: 160px;
      width: 100%;
    }
  }

  .CardModalWindowTitleAvailable {
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    color: #333333;
    display: flex;
    align-items: center;
    padding-left: 17px;
    border-bottom: 1px solid #ECECEC;
    height: 58px;
    cursor: pointer;
    position: relative;

    &[data-title="Ссылка скопирована"]:hover:after {
      opacity: 1;
      transition: all 0.1s ease 0.5s;
      visibility: visible;
    }

    &[data-title="Ссылка скопирована"]:after {
      content: attr(data-title);
      background: #F2F2F2;
      border-radius: 11px;
      color: #333333;
      font-size: 16px;
      line-height: 14px;
      position: absolute;
      padding: 6px 9px 5px 10px;
      bottom: -1.6em;
      white-space: nowrap;
      opacity: 0;
      z-index: 99999;
      visibility: hidden;
      left: 0;
    }

    span {
      margin-top: 4px;
      margin-left: 14px;
    }
  }

  .CardModalWindowContent {
    display: flex;
    flex-direction: column;
    height: 194px;

    @include Scroll;

    .CardModalWindowContentAdd {
      display: flex;
      padding-left: 28px;
      padding-top: 21px;
      align-items: center;
      padding-bottom: 5px;

      .CardModalWindowContentAddAction {
        position: relative;
        width: 100%;

        input {
          border: 1px solid #EDEDED;
          box-sizing: border-box;
          border-radius: 3px;
          max-width: 230px;
          width: 100%;
          height: 28px;
          margin-left: 20px;
          padding-left: 8px;
          font-size: 16px;
          line-height: 19px;
          color: #333333;
          position: relative;
        }

        svg:nth-of-type(1) {
          position: absolute;
          width: 11.28px;
          height: 8.45px;
          right: 27px;
          top: 10px;
          cursor: pointer;

          path {
            fill: #E85943;;
          }
        }

        svg:nth-of-type(2) {
          position: absolute;
          width: 9px;
          height: 9px;
          right: 50px;
          top: 10px;
          cursor: pointer;
          z-index: 2;
        }
      }
    }

    .CardModalWindowFavorites {
      display: flex;
      align-items: center;
      height: 24px;
      padding-top: 31px;
      padding-bottom: 20px;
      padding-left: 28px;
      cursor: pointer;
      position: relative;

      svg {
        width: 24px;
        height: 24px;
      }

      svg:last-of-type {
        position: absolute;
        right: 28px;
        width: 9.15px;
        height: 9.15px;

        path {
          stroke: none;
        }
      }

      span {
        padding-left: 12px;
        font-size: 16px;
        line-height: 19px;
        font-weight: bold;
        color: #333333;
        margin-top: 4px;
      }
    }

    .CardModalWindowElement {
      display: flex;
      align-items: center;
      height: 24px;
      padding-top: 21px;
      padding-bottom: 20px;
      padding-left: 28px;
      cursor: pointer;
      position: relative;

      .CardModalWindowElementProjectIcon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
      }

      .CardModalWindowElementAddIcon {
        position: absolute;
        right: 28px;

        svg {
          width: 9.15px;
          height: 9.15px;

          path {
            stroke: none;
          }
        }
      }

      &:hover {
        background: rgba(67, 94, 237, 0.06);

        span {
          color: #124C9A;
        }
      }

      span {
        padding-left: 12px;
        font-size: 16px;
        line-height: 19px;
        font-weight: bold;
        color: #333333;
        margin-top: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 190px;
      }
    }
  }
}

.CardModalWindowElementImgIcon {
  width: 24px;
  height: 24px;
  margin-left: -4px;
}

.CreateProjectInput {
  display: flex;

  input {
    max-width: 230px!important;
    margin-left: 16px!important;
    font-family: 'AXP_REGULAR', sans-serif;
  }
}
