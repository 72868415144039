@import "~@styles/mixins.scss";

.EmptyHistory {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.Content {
  display: flex;
  padding: 30px;
  height: 100vh;
  @include Scroll;
}

.LoaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  transform: scale(2.5);
}

.ProjectName {
  font-size: 26px;
  padding-left: 30px;
  padding-top: 30px;
  font-weight: bold;
}
