@import "~@styles/breakpoints.scss";
@import "~@styles/palette.scss";

.DesignPageMainInfo {
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 0;
  padding: 32px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 52px;
  align-items: center;
  justify-content: space-between;

  .DesignPageMainInfoContent {
    max-width: 615px;
    width: 45%;
    @media only screen and (max-width: $md) {
      width: 100%;
    }

    p > em {
      font-weight: bold;
    }

    p {
      padding: initial;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.0124em;
      color: #333333;
      margin: 0;
    }

    ul, ol {
      padding-top: initial;
      padding-bottom: initial;
      margin: initial;
      li {
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.0124em;
        color: $gray1;
      }
    }

    .DesignPageAdditionalContentNotes {
      display: flex;
      margin-top: 30px;

      p {
        font-size: 14px;
        line-height: 20px;
        color: $gray1;
        font-family: 'AXP_LIGHT', sans-serif;
      }
    }
  }

  .DesignPageAdditionalContent {
    max-width: 21vw;
    width: 50%;
    height: max-content;
    margin-right: 200px;
    @media only screen and (max-width: $lg) {
      max-width: 28vw;
    }
    @media only screen and (max-width: $md) {
      max-width: 780px;
      margin: 0 200px 0 auto;
      width: 100%;
    }
    @media only screen and (max-width: $sm) {
      max-width: 45vw;
      margin: 0 auto;
      transform: translate(-12vw, 0);
    }
    @media only screen and (max-width: $xs) {
      max-width: unset;
      transform: none;
    }

    .DesignPageAdditionalContentContainer {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: #333333;
      background: #E7EFFD;
      border-radius: 12px;
      box-sizing: border-box;
      border-radius: 12px;
      position: relative;
      padding: 36px;
      max-width: 405px;
      width: 100%;
      min-height: 232px;
      @media only screen and (max-width: $lg) {
        font-size: 1.667vw;
      }
      @media only screen and (max-width: $md) {
        font-weight: 500;
        font-size: 19.2998px;
        line-height: 21px;
      }

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 0 20px 20px;
        border-color: transparent transparent transparent #E7EFFD;
        position: absolute;
        right: -20px;
        top: 20px;
        @media only screen and (max-width: $xs) {
          display: none;
        }
      }

      &:after {
        content: "";
        background: url("~@assets/woman.svg") center center no-repeat;
        background-size: contain;
        width: 204px;
        height: 232px;
        position: absolute;
        top: 0;
        right: -200px;
        @media only screen and (max-width: $sm) {
          width: 25vw;
          right: -28vw;
        }
        @media only screen and (max-width: $xs) {
          display: none;
        }
      }
    }
  }
}

.UsefulLinkContainer {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-top: 32px;

  .UsefulLink {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: $gray1;
    position: relative;
    margin-left: 35px;

    &:before {
      content: "";
      position: absolute;
      background: url("~@assets/useful_icon.png") 0 0 no-repeat;
      width: 35px;
      height: 32px;
      left: -35px;
      top: -12px;
    }
  }
}
