@import "~@styles/palette.scss";

.InvitedFormModal {
  padding-top: 20px;
  z-index: 1!important;
}

.InvitedForm {

  .InvitedFormTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $gray1;
    text-align: left;
    max-width: 264px;
    width: 100%;
    padding-left: 18px;
  }

  .InvitedFormContainer {
    margin-top: 40px;

    .InvitedFormContainerField {
      position: relative;
      margin-bottom: 14px;

      label {
        display: flex;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: $gray3;
        margin-bottom: 8px;
        padding-left: 18px;
      }

      .InvitedFormInput {
        justify-content: center;

        input {
          background: #FFFFFF;
          border: 1px solid #EDEDED;
          box-sizing: border-box;
          border-radius: 6px;
          height: 36px;
          max-width: 303px;
          width: 100%;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: $gray1;
          font-family: 'AXP_REGULAR', sans-serif;
          padding-left: 12px;
        }
      }

      .InvitedFormInputError {
        padding-left: 18px;
      }

      .InvitedFormSelect {
        max-width: 130px;
        width: 100%;
        position: absolute;
        top: 30px;
        right: 17px;
        border: none;
      }
    }
  }

  .InvitedFormActions {
    display: flex;
    justify-content: center;
    margin-top: 39px;
    margin-bottom: 62px;

    .InvitedFormBtn {
      max-width: 147px;
      height: 36px;
      width: 100%;
      background: $blueMain;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
    }
  }

  .InvitedFormAddInvited {
    background: transparent;
    margin-top: 15px;
    margin-bottom: 13px;
    display: flex;
    align-items: center;
    padding: 0 0 0 18px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $gray1;
    max-width: 170px;
    width: 100%;

    svg {
      margin-right: 12px;
      width: 25px;
      height: 25px;
    }
  }
}

.SuccessModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $gray1;
    margin-bottom: 62px;
  }
}