@import "~@views/PrivateCabinet/components/PersonalData/PersonalData";
@import "~@styles/palette.scss";

.SuccessChangeEmail {

}

.ChangeEmailForm {

  .ChangeEmailFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &CodeContainer {
      width: 200px;
      margin: 0 auto;

      &Code {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #BDBDBD;
        white-space: nowrap;
        display: block;
        text-align: center;
        margin: 30px 0 30px 0;
      }

      & > p {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: $gray3;
        padding: initial;
        margin: initial;
      }

      &Btn {
        background: #1CAB88;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: white;
        height: 36px;
        margin: 30px 0 30px 0;
      }

      &Inputs {
        display: flex;
        margin-top: 8px;

        &Errors {
          & > div > div > input {
            border: 1px solid #E85943!important;
          }
        }

        & > div > div {
          display: flex!important;
          column-gap: 5px;

          input {
            background: #FFFFFF;
            border: 1px solid #EDEDED!important;
            width: 36px!important;
            height: 40px!important;
            border-radius: 6px;
            box-shadow: none!important;
            padding: initial!important;
            margin: initial!important;
            text-align: center;
            font-family: 'AXP_REGULAR', sans-serif;
          }
        }
      }
    }

    .ChangeEmailFormField {
      display: flex;
      flex-direction: column;
      max-width: 268px;
      width: 100%;

      label {
        @include Label;
        font-family: AXP_LIGHT, sans-serif;
      }

      input {
        @include Input;
        background: #FFFFFF;
        height: 36px;
        font-weight: 600;
        font-family: 'AXP_REGULAR', sans-serif;
      }
    }

    .ChangeEmailFormFieldBtn {
      @include SubmitButton;
      margin-top: 40px!important;
    }
  }
}

.SuccessChangePsw {
  display: flex;
  max-width: 338px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;

  h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.0125em;
    color: #333333;
    margin-bottom: 12px;
  }

  p {
    margin: 0 0 62px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.0125em;
    color: #333333;
  }

  span {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    font-family: 'AXP_LIGHT', sans-serif;
    letter-spacing: -0.0125em;
    text-align: center;
    color: #333333;
    margin-top: 30px;
    margin-bottom: 62px;
  }
}
