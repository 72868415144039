@import "~@styles/breakpoints.scss";
@import "~@styles/mixins.scss";
@import '~@styles/palette.scss';

$borderStyle: 1px solid #ECECEC;
$paddingLeft: 24px;

@mixin ActiveNavLink {
  h4 {
    color: $blue;
  }

  background-color: #F4F4F4!important;

  &:before {
    content: "";
    height: 38px;
    border-left: 2px solid $blue;
    left: 0;
    top: 0;
  }

  svg {
    path, circle {
      stroke: $blue;
    }
  }
}

@mixin ListElement {
  display: flex;
  align-items: center;
  height: 38px;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  //margin: 25px 0 25px 0;

  &:hover {
    h4 {
      color: #124C9A;
    }
    background-color: #F4F4F4;
  }

  h4 {
    padding-left: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    max-width: 135px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (max-width: $md) {
      display: none;
    }
    @media only screen and (max-width: $xs) {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      display: block;
    }
  }

  i {
    position: absolute;
    width: 16px;
    height: 20px;
    right: 10px;
  }

  i[data-favorite="true"] {
    background-position: 4px 0!important;
    right: 14px;
  }

  i:last-of-type {
    background: url("~@assets/carbon_vertical.svg") 0 0 no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-top: -4px;
  }
}

.Root {
  display: flex;
  height: calc(100% - 59px);
  margin-bottom: 100px;
  @media only screen and (max-width: $xs) {
    flex-direction: column;
  }

  .ProjectColumn {
    max-width: 229px;
    width: 100%;
    border-right: $borderStyle;
    height: 100%;
    @media only screen and (max-width: $md) {
      max-width: 76px;
      &[data-open = "true"] {
        max-width: 35vw;
        min-width: 170px;
        // a {
        //   width: auto !important;
        // }
        .CreateAction {
          .Create {
            span {
              display: flex !important;
            }
          }
        }
        h4 {
          display: flex !important;
        }
      }
    }
    @media only screen and (max-width: $xs) {
      max-width: unset !important;
      border-right: none !important;
    }

    .ProjectMenuTrigger {
      display: none;
      @media only screen and (max-width: $md) {
        display: block;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("~@assets/arrow_open.svg");
        width: 18px;
        height: 13px;
        margin: 23px auto;
        cursor: pointer;
        &[data-active = "true"] {
          transform: rotate(-180deg);
        }
      }
      @media only screen and (max-width: $xs) {
        display: none;
      }
    }

    .Title {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #4F4F4F;
      border-bottom: 1px solid #ECECEC;
      height: 58px;
      display: flex;
      align-items: center;
      padding-left: $paddingLeft;
    }

    .CreateAction {

      .Create {
        display: flex;
        align-items: center;
        background: transparent;
        border-radius: initial;
        height: 58px;
        border-bottom: $borderStyle;
        padding-left: $paddingLeft;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
        @media only screen and (max-width: $md) {
          height: 58px;
          padding-left: 17px;
        }
        @media only screen and (max-width: $xs) {
          border-bottom: none;
        }

        svg {
          margin-right: 12px;

          circle {
            fill: $green;
          }
        }
        span {
          @media only screen and (max-width: $md) {
           display: none;
          }
          @media only screen and (max-width: $xs) {
            display: block;
          }
        }
      }
    }

    .ProjectsList {
      margin-top: 20px;
      height: calc(100% - 147px);
      // overflow-y: auto;
      // @include Scroll;
      overflow: visible;
      display: flex;
      flex-direction: column;
      row-gap: 25px;

      .CreateProjectInput {
        display: flex;
        align-items: center;
        column-gap: 7px;
        position: relative;

        .CreateProjectInputImg {
          width: 24px;
          height: 24px;
          margin-left: 16px;
        }

        textarea {
          border: 1px solid #ECECEC;
          border-radius: 3px;
          max-width: 170px;
          width: 100%;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #333333;
          font-family: 'AXP_REGULAR', sans-serif;
          padding: 6px;
        }
      }

      .ProjectListElement {
        @include ListElement;

        & > i:first-child {
          margin-top: -8px;
        }
      }

      .ProjectListFavoriteElement {
        @include ListElement;

        & > svg:first-child {
          margin-top: -8px;
          margin-left: 20px;
        }

        &:hover {
          svg {
            path, circle {
              stroke: $blue;
            }
          }
        }
      }

      .ActiveProjectListElement {
        @include ActiveNavLink;
      }
    }
  }

  .PassportsColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .Filtration {
      display: flex;
      align-items: center;
      height: 58px;
      border-bottom: $borderStyle;
      justify-content: space-between;
      @media only screen and (max-width: $xs) {
        border-top: $borderStyle;
      }

      .SearchFiltration {
        display: flex;

        h4 {
          padding-left: $paddingLeft;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #4F4F4F;
          @media only screen and (max-width: $md) {
            display: none;
          }
        }

        .SearchField {
          margin-left: 58px;
          position: relative;
          display: flex;
          align-items: center;
          @media only screen and (max-width: $md) {
            margin-left: 50px;
          }
          @media only screen and (max-width: $xs) {
            margin-left: 45px;
          }

          svg {
            position: absolute;
            width: 16px;
            height: 16px;
            left: -22px;

            path {
              stroke: #828282;
            }
          }

          input {
            border: none;
            width: 100%;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #828282;
            font-family: 'AXP_REGULAR', sans-serif;
            @media only screen and (max-width: $md) {
              font-size: 1.5vw;
              line-height: 1.5vw;
            }
            @media only screen and (max-width: $sm) {
              font-size: 2.3vw;
              line-height: 2.5vw;
            }
            @media only screen and (max-width: $xs) {
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              padding: 2px 0 0 8px;
            }
          }
        }
      }

      .AdditionalFilter {
        display: flex;
        padding-right: 5px;
        position: relative;

        .Button {
          background: transparent;
          display: flex;
          align-items: center;
          padding-left: 25px;
          padding-right: 25px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #828282;

          @media only screen and (max-width: $md) {
            padding-left: 0;
            padding-right: 15px;
          }

          @media only screen and (max-width: $xs) {
            padding-left: 0;
            padding-right: 15px;
          }

          svg {
            margin-right: 13px;
            width: 17px;
            height: 17px;
          }
        }

        .ChoiceContextMenu {
          left: 65px;
          top: 40px;

          ul {
            display: flex;
            flex-direction: column;
            row-gap: 7px;

            .ContextMenuDeleteElement {
              color: $accentRed;
            }

            .ContextMenuCancel {
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              color: $gray3;
            }
          }

          .ChoiceContextMenuMovementProject {
            display: flex;
            padding-left: 20px;
            row-gap: 32px;
            margin-top: 32px;
            margin-bottom: 32px;
            max-height: 400px;
            overflow-y: auto;
            padding-top: 5px;
            @include Scroll;

            & > div {
              display: flex;
              align-items: center;

              img, svg {
                margin-right: 10px;
                margin-top: -2px;
                width: 20px;
                height: 20px;
              }

              span {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: $gray1;
                cursor: pointer;
                display: block;
                width: 120px;
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &:hover {
                  color: $blueMain;
                }
              }
            }
          }
        }

        .ButtonSearch {
          align-items: center;
          display: flex;
          background: transparent;
          color: $blueMain;
          border-radius: 5px;
          border: 1px solid $blueMain;
          height: 32px;
          padding-left: 10px;
          padding-right: 10px;
          margin-right: 24px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          @media only screen and (max-width: $md) {
            padding-left: 0;
            padding-right: 0;
            border: none;
          }

          @media only screen and (max-width: $xs) {
            padding-left: 0;
            padding-right: 0;
            border: none;
          }

          svg {
            margin-right: 13px;
            width: 17px;
            height: 17px;

            path {
              fill: $blueMain;
            }
          }

          & > span {
            white-space: nowrap;
          }
        }

        .ButtonActive {
          color: $blueMain;

          svg {
            path {
              fill: $blueMain;
            }
          }
        }
      }
    }

    .PassportsContent {
      max-height: 787px;
      @include Scroll;
      @media only screen and (max-width: $md) {
        max-height: 600px;
      }
      @media only screen and (max-width: $sm) {
        max-height: 787px;
      }
      & > div {
        display: flex;
      }

      & > div > div {
        padding-left: 30px;
        padding-top: 30px;
        display: flex;
        gap: 22px;
        flex-wrap: wrap;
        overflow-y: auto;
        padding-bottom: 110px;
        flex: 1 auto;
        overflow-x: hidden!important;

        @media only screen and (max-width: $xs) {
          padding-left: 10px;
          padding-right: 5px;
          padding-top: 10px;
          padding-bottom: 40px;
        }

        & > span {
          width: 100%;
          display: flex;
          gap: 22px;
          flex-wrap: wrap;
        }

        .PassportsContentEmpty {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .PassportsContentEmptyBtn {
            max-width: 161px;
            width: 100%;
            height: 36px;
            background: $green;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: white;
            text-decoration: none;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }

          & > h4 {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: $gray1;
          }

          & > p {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: $gray1;
            padding: initial;
            margin: 4px 0 30px 0;
          }

          .PassportsContentEmptyIcon {
            width: 99px;
            height: 99px;
            background: $gray6;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;

            svg {
              width: 25px;
              height: 33px;

              path {
                stroke: #BDBDBD;
              }
            }
          }
        }
      }
    }
  }
}

.ContextMenu {
  top: 53px;
  left: 13px;

  ul > li  {
    margin-bottom: 12px;

    span {
      font-size: 12px;
      line-height: 14px;
      color: #828282;
    }
  }

  &ChangeProjectName {
    padding: 0 4px 0;
    max-width: 134px;
    width: 100%;
    margin-left: 15px;
    margin-bottom: 15px;
    position: relative;
    margin-right: 10px;
  }

  &ChangeProjectNameIcon {
    cursor: pointer;
  }
}

.ContextMenuDeleteElement {
  color: #E85943!important;
}

.ContextMenuAuthorElement {
  display: inherit!important;
  color: #124C9A!important;
}

.CreateProjectImg {
  width: 24px;
  height: 24px;
  margin-left: 16px;
}

.SkeletonItems {
  max-width: 158px;
  width: 100%;
  height: 162px;
}

.ModalRoot {

  @media only screen and (max-width: $md) {
    right: 0!important;
  }

  @media only screen and (max-width: $xs) {
    right: 0!important;
  }

  .ModalContent {
    padding: 0 0 0 16px;
    display: flex;
    flex-direction: column;
    row-gap: 27px;
  }
}

.FiltrationApplicationArea, .HandbookFiltrationContainer, .HandbookFiltrationContainer {
  & > span {
    display: block;
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $gray1;
  }
}

.FilterElementLifeTime {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 12px 0 0 0;

  span {
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    color: #333333;
    padding-right: 9px;
  }

  .FilterElementInputContainer {
    display: flex;
    margin-right: 0;
    margin-top: 8px;

    .FilterElementLifeTimeForm {
      display: grid;
      grid-template-columns: 39px;

      & > div:nth-child(1) {
        grid-column: 1;
        input {
          border-radius: 5px;
        }
      }

      & > div:nth-child(2) {
        grid-column: 2;
        input {
          visibility: hidden;
        }
      }
    }

    .ResetFilter {
      grid-column: 1;
      grid-row: 2;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #124C9A;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 27px;
      margin-top: 27px;
    }

    label {
      background: #F9F9F9;
      border-radius: 5px 0 0 5px;
      box-sizing: border-box;
      max-width: 39px;
      width: 100%;
      border: 1px solid #E0E0E0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #BDBDBD;
      font-size: 14px;
      line-height: 17px;
      font-weight: bold;
    }

    .FilterElementInput {
      height: 28px;
      max-width: 39px;
      width: 100%;

      input {
        max-width: 39px;
        width: 100%;
        background: #fff;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
        border: 1px solid #E0E0E0;
        text-align: center;
        background: #FFFFFF;
        border-radius: 5px;
        &[value = ""] {
          background: #F9F9F9;
        }
      }
    }
  }
}

.SuccessMoveModalRoot {
  right: 50%;
  left: 50%!important;
  top: 200px;

  @media only screen and (max-width: $md) {
    max-width: 300px!important;
    right: initial;
    left: initial!important;
    top: initial;
  }

  @media only screen and (max-width: $xs) {
    right: initial;
    left: initial!important;
    top: 550px;
  }

  .SuccessMoveModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 30px 0;

    & > p, a {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: $gray1;
      text-align: center;

      a {
        padding-left: 4px;
        text-decoration: none;
      }
    }

    .SuccessMoveModalCancelBtn {
      font-size: 18px;
      line-height: 20px;
      color: $gray3;
      background: transparent;
    }
  }
}

.ProjectNameError {
  border: 1px solid crimson!important;

  &Msg {
    position: absolute;
    color: crimson;
    font-size: 12px;
    top: 52px;
    left: 19px;
  }
}


