@import "~@styles/breakpoints.scss";
@import "~@styles/mixins.scss";

.Root {
  display: flex;

  .Container {
    @include ContainerLg;
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    padding: 90px 40px 40px 40px;
    box-sizing: content-box;
    @media only screen and (max-width: $xs) {
      padding: 73px 20px 20px 20px;
    }

    .BreadCrumbs {
      margin-bottom: 0;
      @media only screen and (max-width: $xs) {
        margin-bottom: 30px;
        height: 37px;
        margin-top: 10px;
      }
    }
  }
}
