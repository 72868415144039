@import "~@styles/breakpoints.scss";
@import "~@styles/mixins.scss";
@import '~@styles/palette.scss';

.Root {
  display: flex;

  .Container {
    @include ContainerLg;
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    padding: 90px 40px 40px 40px;
    box-sizing: content-box;
    @media only screen and (max-width: $xs) {
      padding: 73px 20px 20px 20px;
    }

    .Tabs {
      position: relative;
      display: flex;
      gap: 58px;
      border-bottom: 1px solid #EDEDED;
      padding: 60px 0 0 0;

      @media only screen and (max-width: $md) {
        padding: 40px 0 0 0;
      }

      @media only screen and (max-width: $xs) {
        justify-content: space-between;
      }

      &:after {
        content: '';
        position: absolute;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("~@assets/usefull_man.png");
        right: 0;
        bottom: 0;
        width: 202px;
        height: 98px;
        @media only screen and (max-width: $md) {
          width: 163px;
          height: 79px;
        }
        @media only screen and (max-width: $sm) {
          width: 22vw;
          height: 11vw;
        }
        @media only screen and (max-width: $xs) {
          display: none;
        }
      }

      .Tab {
        font-weight: 600;
        font-size: 32px;
        line-height: 64px;
        color: $gray3;
        width: initial;
        background: transparent;
        border-radius: initial;
        height: 64px;
        white-space: nowrap;

        &:hover {
          color: $blueMain;
        }
        @media only screen and (max-width: $lg) {
          font-size: 24px;
          line-height: 64px;
        }
      }

      .ActiveTab {
        color: $blueMain;
        border-bottom: 2px solid #124C9A;
      }
    }
  }
}
