@import "~@styles/breakpoints.scss";
@import '~@styles/palette.scss';

.PDF {
  position: absolute;
  right: 10px;
}

.Root {
  margin: 90px auto 0;
  width: 100%;
  background: #EFF2FA;

  .Content {
    display: flex;
    flex-direction: column;
    max-width: 648px;
    width: 100%;
    background: white;
    margin: 0 auto;
    padding: 0 40px 160px 40px;
    height: 100%;
    min-height: 536px;
    @media only screen and (max-width: $md) {
      max-width: unset;
    }
    @media only screen and (max-width: $xs) {
      padding: 0 20px 160px 20px;
    }

    .Title {
      font-weight: bold;
      font-size: 24px;
      line-height: 23px;
      color: $gray1;
      margin-bottom: 11px;
    }

    .Description {
      margin-bottom: 24px;
    }

    .Actions {
      margin-top: 30px;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      position: relative;

      .ActionsNavigation {
        font-weight: bold;
        font-size: 36px;
        line-height: 64px;
        color: $gray1;
        text-decoration: none;
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 62px;
        @media only screen and (max-width: $sm) {
          font-size: 24px;
          line-height: 32px;
        }

        &:before {
          content: "";
          background: url("~@assets/back.svg") 0 0 no-repeat;
          width: 40px;
          height: 40px;
          position: absolute;
          margin-left: -62px;
          margin-top: -4px;
        }

        &:hover {
          color: $blueMain;
        }
      }

      svg {
        cursor: pointer;
      }
    }

    img {
      max-width: 648px;
      width: 100%;
      padding-bottom: 24px;
      padding-top: 24px;
    }

    p {
      margin: initial;
    }

    ol, ul {
      margin-bottom: 14px;
      margin-left: 15px;
    }
  }
}

.CheckListContent {
  height: 100%;

  & > p {
    border-bottom: 1px solid #D9DAE6;

    & > span {
      display: block;
      width: 65%;
      border-right: 1px solid #D9DAE6;
      height: 100%;
      padding: 20px 32px 20px 0;
    }
  }

  & > p:last-child {
    border-bottom: none;
  }
}
