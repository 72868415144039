@import "~@styles/breakpoints.scss";

.Root {
  max-width: 844px;
  width: 100%;
  height: 76px;
  background: #FFFEFE;
  border: 2px solid #E85943;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 44px;
  left: 0;
  right: 0;
  z-index: 1000;
  margin: 0 auto;

  @media only screen and (max-width: $md) {
    flex-direction: column;
    height: 120px;
  }

  &Title {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #333333;
    margin-left: 29px;

    @media only screen and (max-width: $md) {
      line-height: 16px;
      margin-right: 29px;
    }

    @media only screen and (max-width: $sm) {
      font-size: 14px;
    }
  }

  &Worm {
    width: 118px;
    height: 110px;
    margin-top: -19px;
    margin-right: 28px;

    @media only screen and (max-width: $md) {
      position: absolute;
      width: 80px;
      height: 80px;
      right: 0;
      bottom: -10px;
    }
  }

  &Btn {
    max-width: 116px;
    width: 100%;
    height: 44px;
    background: #1CAB88;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin-right: 19px;

    @media only screen and (max-width: $md) {
      margin-bottom: 20px;
    }
  }
}
