@import "~@styles/breakpoints.scss";

.DesignPageContent {
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 20px;
  @media only screen and (max-width: $sm) {
    flex-wrap: wrap;
  }

  & > span {
    .SkeletonContentImg {
      max-width: 376px;
      width: 100%;
      height: 376px;
    }
  }

  
  .DesignPageContentImg {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 376px;
    width: 33.3%;
    height: 374px;
    margin-top: 4px;
    overflow: hidden;
    margin-bottom: 22px;
    @media only screen and (max-width: $md) {
      width: 40%;
    }
    @media only screen and (max-width: $sm) {
      min-width: 40%;
      flex: 1 auto;
      max-width: unset;
      width: auto;
    }
    img {
      min-width: 100%;
      min-height: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .DesignPageContentAdd {
      display: none;
      @media only screen and (max-width: $md) {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: #E85943;
        background-image: url("~@assets/plus.svg");
        z-index: 1;
        right: 8px;
        top: 8px;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        cursor: pointer;
        &[data-active = "true"] {
          background-image: url("~@assets/close_gray.svg");
        }
      }
    }
  }

  .DesignPageContentDescription {
    margin-left: 24px;
    margin-top: 4px;
    width: 33.3%;
    margin-bottom: 22px;
    @media only screen and (max-width: $md) {
      width: 60%;
    }
    @media only screen and (max-width: $sm) {
      flex: 1;
      margin-left: 0;
    }

    .DesignPageContentDescriptionCharacteristicsContainer {
      display: flex;
      column-gap: 34px;
      justify-content: space-between;

      .FilterApplicationArea {
        display: flex;
        flex-wrap: wrap;
        margin-right: 24px;
        margin-bottom: 16px;

        .FilterApplicationAreaTitle {
          font-size: 16px;
          line-height: 28px;
          font-family: 'AXP_BOLD', sans-serif;
          letter-spacing: 0.02em;
          color: #333333;
          display: block;
          margin-bottom: 4px;
        }

        .FilterApplicationAreaRoot {
          display: block;
        }
      }

      .FilterComplexityInstallation {
        margin-top: 48px;
        margin-bottom: 16px;

        .FilterComplexityInstallationTitle {
          font-size: 16px;
          line-height: 28px;
          font-family: 'AXP_BOLD', sans-serif;
          letter-spacing: 0.02em;
          color: #333333;
          display: block;
          margin-bottom: 4px;
        }

        .FilterComplexityInstallationRoot {
          display: block;
        }
      }

      .FilterCost {
        margin-bottom: 16px;

        .FilterCostTitle {
          font-size: 16px;
          line-height: 28px;
          font-family: 'AXP_BOLD', sans-serif;
          letter-spacing: 0.02em;
          color: #333333;
          display: block;
          margin-bottom: 4px;
        }

        .FilterCostRoot {
          display: block;
        }
      }

      .LifeTime {
        margin-top: 48px;

        .LifeTimeTitle {
          font-size: 16px;
          line-height: 28px;
          font-family: 'AXP_BOLD', sans-serif;
          letter-spacing: 0.02em;
          color: #333333;
          display: block;
          margin-bottom: 4px;
        }

        .LifeTimeContent {
          font-size: 16px;
          line-height: 20px;
          color: #333333;
          margin: 6px 0 6px 0;
        }
      }
    }

    .DesignPageContentDescriptionTitle {
      font-weight: bold;
      font-size: 28px;
      line-height: 28px;
      letter-spacing: -0.0125em;
      color: #333333;
    }

    .DesignPageContentDescriptionSubTitle {
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      padding-top: 8px;
      margin: 0;
      max-width: 431px;
      width: 100%;
    }

    .DesignPageContentTable {
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      text-decoration-line: underline;
      color: #333333;
      margin-top: 40px;
      cursor: pointer;
    }
  }

  .ModalWithListProjectsRoot {
    top: 0;
    right: 0;
    left: initial;
    box-shadow: none;
    height: 376px;
    @media only screen and (max-width: $md) {
      display: none;
      &[data-opened = "true"] {
        display: block;
        background: #FFFFFF;
        top: 60px;
        right: auto;
        box-shadow: 0px 2px 30px rgba(178, 181, 194, 0.29);
        border-radius: 0px 0px 6px 6px;
      }
    }
    @media only screen and (max-width: $sm) {
      &[data-opened = "true"] {
        right: 0;
      }
    }
  }

  .ModalWithListProjectsList {
    height: 275px;
  }
}
