@import '~@styles/palette.scss';
@import "~@styles/fonts.scss";

:global html {
  box-sizing: border-box;
}

:global body {
  font-family: "Fira_Sans_Regular", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:global * {
  box-shadow: none;
  box-sizing: inherit;

  &::after,
  &::before {
    box-sizing: inherit;
  }

  &:focus {
    outline: none;
  }
}

:global h1,
:global h2,
:global h3,
:global h4,
:global h5,
:global h6 {
  margin: 0;
}


:global h1 {
  font-family: MontserratBold, sans-serif;
  font-style: normal;
  font-size: 48px;
  line-height: 61px;
}

:global h2 {
  font-family: "Fira_Sans_Regular", sans-serif;
  font-style: normal;
  font-size: 40px;
  line-height: 48px;
  color: #2F3441;
}

:global h3 {
  font-family: "Fira_Sans_Regular", sans-serif;
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  color: #2F3441;
}

:global p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

:global button {
  font-family: "Fira_Sans_Regular", sans-serif;
}

:global ul {
  padding: initial;
  margin: initial;
}
