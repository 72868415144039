@import '~@styles/reset.scss';
@import '~@styles/palette.scss';

.Root {
  background: #EFF2F9;
  min-height: 100vh;
  position: relative;
  display: flex;
  width: 100%;
}
