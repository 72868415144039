@import '~@styles/palette.scss';
@import "~@styles/breakpoints.scss";

.LoginForm {
  //max-width: 387px;
  width: 100%;
  //height: 410px;
  height: 100vh;
  box-shadow: 0 2px 30px rgba(178, 181, 194, 0.29);
  border-radius: 6px;
  background: #fff;
  position: fixed;
  right: 0;
  left: 0;
  margin: 0 auto;
  //top: 140px;
  //z-index: 3;
  z-index: 23;

  @media only screen and (max-width: $sm) {
    height: 100vh;
    top: 80px;
    overflow-y: auto;
    max-width: initial;
  }

  &Title {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.0125em;
    color: #000000;
  }

  &Container {
    max-width: 268px;
    width: 100%;
    margin: 0 auto;

    &Field {
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      label {
        color: #828282;
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 8px;
      }

      input {
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        box-sizing: border-box;
        border-radius: 6px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.0125em;
        color: #333333;
        padding-top: 7px;
        padding-bottom: 5px;
        padding-left: 12px;
        position: relative;

        &::placeholder {
          color: #BDBDBD;
        }
      }

      &Psw {
        position: relative;
        width: 100%;

        input {
          width: 100%;
          letter-spacing: -0.0125em;
        }

        i {
          &[data-show="false"] {
            background: url("~@assets/show_password.svg") 0 0 no-repeat;
            top: 6px;
            right: 9px;
            position: absolute;
            width: 20px;
            cursor: pointer;
            height: 20px;
          }

          &[data-show="true"] {
            background: url("~@assets/show_psw.svg") 0 0 no-repeat;
            position: absolute;
            width: 20px;
            height: 8px;
            right: 7px;
            top: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }

  &Actions {
    max-width: 268px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 18px auto 0;

    button {
      background: #124C9A;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      font-family: 'AXP_BOLD', sans-serif;
      max-width: 118px;
      height: 36px;
    }

    &Socials {
      display: flex;
      column-gap: 7px;

      @media only screen and (max-width: $sm) {
        margin-bottom: 120px;
      }

      a, i {
        display: block;
        width: 36px;
        height: 36px;
        cursor: pointer;
      }

      &Google {
        background: url("~@assets/google_mail.svg") 0 0 no-repeat;
      }

      &FB {
        background: url("~@assets/facebook_mail.svg") 0 0 no-repeat;
      }

      &VK {
        background: url("~@assets/vk_mail.svg") 0 0 no-repeat;
      }
    }

    &Registration {
      font-size: 14px;
      line-height: 20px;
      color: #828282;
      margin-top: 8px;
      cursor: pointer;
    }
  }
}

.Close {
  background: url("~@assets/close.svg") 0 0 no-repeat;
  position: absolute;
  top: 18px;
  right: 18px;
  width: 13px;
  height: 13px;
  cursor: pointer;
}

.Form {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  z-index: 1;
}

.LoginFormContainerFieldPswSignature {
  font-size: 14px;
  line-height: 20px;
  color: $gray3;
  margin-top: 8px;
  cursor: pointer;
}
