@import "~@styles/breakpoints.scss";

.ImplementationTabs {
  display: flex;

  .ImplementationPageTabsTab {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #E85943;
    width: initial;
    background: transparent;
    border-radius: 0;
    height: 55px;
    padding: 0;
    cursor: initial;
    border-bottom: 2px solid #E85943;
  }
}

.MainInfo {
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 0;
  padding: 32px;
  margin-bottom: 52px;

  .MainInfoContent {
    display: flex;
    column-gap: 50px;
    @media only screen and (max-width: $sm) {
      flex-wrap: wrap;
    }

    .MainInfoDescription {
      margin-right: 52px;
      max-width: 615px;
      width: 100%;
      @media only screen and (max-width: $sm) {
        margin-right: 0;
      }

      & > p > strong {
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.0124em;
        color: #333333;
        margin: 0;
      }

      p {
        padding: initial;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.0124em;
        color: #333333;
        margin: 0;
      }

      ul, ol {
        li {
          font-size: 18px;
          line-height: 20px;
          letter-spacing: -0.0124em;
          color: #333333;
        }
      }
    }

    .MainInfoImgsContent {
      display: flex;
      flex-direction: column;
      row-gap: 50px;

      img {
        max-width: 615px;
        width: 100%;
      }
    }
  }

  .MainInfoFaq {
    width: 80%;
    margin: 64px 0 22px;
    box-sizing: content-box;
    border-radius: 12px;
    @media only screen and (max-width: $lg) {
      width: 75%;
    }
    @media only screen and (max-width: $md) {
      max-width: unset;
      margin: 40px 29vw 40px auto;
      width: 70%;
    }
    @media only screen and (max-width: $xs) {
      max-width: unset;
      width: 100%;
      margin: 20px auto;
    }

    .MainInfoFaqContent {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: #333333;
      padding: 36px;
      position: relative;
      background: #FFF1ED;
      border-radius: 12px;
      @media only screen and (max-width: $md) {
        font-weight: 500;
        font-size: 19.2998px;
        line-height: 21px;
      }

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 0 20px 20px;
        border-color: transparent transparent transparent #FFF1ED;
        position: absolute;
        right: -20px;
        top: 20px;
        z-index: 1;
        @media only screen and (max-width: $xs) {
          display: none;
        }
      }

      &:after {
        content: "";
        background: url("~@assets/woman_red.svg") center center no-repeat;
        background-size: contain;
        width: 204px;
        height: 232px;
        position: absolute;
        top: 0px;
        right: -260px;
        @media only screen and (max-width: $md) {
          width: 20vw;
          right: -25vw;
        }
        @media only screen and (max-width: $xs) {
          display: none;
        }
      }
    }
  }
}
