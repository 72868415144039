@import "~@styles/palette.scss";
@import "~@styles/breakpoints.scss";

.Root {
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 0;
  padding: 32px;
  margin-bottom: 52px;

  @media only screen and (max-width: $sm) {
    padding: 24px;
  }

  .ImgsContent {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 28px;
    margin-bottom: 39px;

    .ImgsContentElement {
      display: flex;
      align-items: center;
      margin-right: 40px;
      max-width: 210px;
      width: 100%;
      margin-bottom: 12px;

      img {
        max-width: 84px;
        width: 100%;
        height: 84px;
      }

      span {
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.02em;
        color: #333333;
        padding-left: 16px;
      }
    }
  }
}
