@import "~@styles/fonts.scss";

.Root {
  width: 100%;
  border-radius: 6px;
  font-family: "Fira_Sans_Regular", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  border: none;
  cursor: pointer;
}
