@import "~@styles/breakpoints.scss";
@import '~@styles/palette.scss';
@import "~@styles/mixins.scss";

.DnDRoot {

  .Preview {
    height: 84px!important;

    img {
      width: 69px!important;
      height: 69px!important;
    }

    h4 {
      font-size: 6px!important;
      line-height: 8px!important;
      padding: 10px!important;
    }
  }

  .CardIcons {
    transform: scale(0.5);
    margin-top: -3px!important;
  }
}

.Root {
  max-width: 174px;
  width: 100%;
  max-height: 222px;
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  text-decoration: none;
  @media only screen and (max-width: $md) {
    max-height: 181px;
  }
  @media only screen and (max-width: $xs) {
    max-width: unset;
    max-height: 290px;
  }

  &:active {
    transform: scale(0.5);
  }

  &[data-is-open-context-menu = "true"] {
    &:active {
      transform: none!important;
    }
  }

  &[data-is-multiply = "true"] {
    &:active {
      transform: none!important;
    }
  }

  &[data-dragging = "true"] {
    transform: scale(0.5);
  }

  .Preview {
    position: relative;
    padding: 15px 15px 0;
    height: 180px;
    @media only screen and (max-width: $md) {
      height: 140px;
    }
    @media only screen and (max-width: $xs) {
      height: 250px;
    }

    h4 {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 0;
      padding: 23px;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.0125em;
      color: #FFFFFF;
      background: linear-gradient(360deg, #191C39 -2.47%, rgba(35, 39, 71, 0) 100%);
    }

    img {
      width: 100%;
      height: 100%;
      @media only screen and (max-width: $xs) {
        // height: 250px;
      }
    }
  }

  .CardIcons {
    padding-left: 15px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    div > div > div {
      width: 16px;
      margin: initial;

      span {
        background-size: 16px;
        padding: initial;
      }
    }
  }
}

.Container {
  display: flex;
  position: relative;
}

.Carbon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url("~@assets/carbon_vertical.svg") 0 0 no-repeat;
  position: absolute;
  right: 9px;
  top: 187px;

  @media only screen and (max-width: $md) {
    top: 147px;
  }

  @media only screen and (max-width: $sm) {
    top: 147px;
  }

  @media only screen and (max-width: $xs) {
    top: 256px;
  }
}

.ContextMenuDeleteElement {
  color: #E85943!important;
}

.ActiveRoot {
  border: 1px solid $blueMain;
  cursor: pointer!important;

  &:active {
    transform: none!important;
  }
}

.ChoiceContextMenuMovementProject {
  display: flex;
  padding-left: 20px;
  row-gap: 32px;
  margin-top: 32px;
  margin-bottom: 32px;
  max-height: 198px;
  overflow-y: auto;
  padding-top: 5px;
  @include Scroll;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: center;

    img, svg {
      margin-right: 10px;
      margin-top: -2px;
      width: 20px;
      height: 20px;
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $gray1;
      cursor: pointer;
      display: block;
      width: 120px;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        color: $blueMain;
      }
    }
  }
}
