@import '~@styles/reset.scss';

.Container {
  display: flex;
  position: relative;
  height: 36px;

  input {
    width: 100%;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:active  {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.0125em;
      outline: none !important;
      box-shadow: transparent !important;
      background: transparent !important;
      -webkit-text-fill-color: inherit !important;
      -webkit-box-shadow: 0 0 0 1000px rgba(255,255,255,0.001) inset !important;
      -webkit-transition-delay: 9999s;
      transition-delay: 9999s;
    }
  }

  .EmptyInput {
    background: #FDFDFD!important;
  }

  .Error {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.0125em;
    color: #E85943!important;
    position: absolute;
    bottom: -16px;
    left: 0!important;
    white-space: nowrap;
  }
}

.ErrorInput {
  border: 1px solid #E85943!important;
  border-radius: 6px!important;
}

