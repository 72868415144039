@import "~@styles/breakpoints.scss";

@mixin button {
  max-width: 196px;
  height: 46px;
  background: #1CAB88;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  display: block;
  margin: 20px auto 0;
  border-radius: 6px;
  @media only screen and (max-width: $lg) {
    margin: 30px auto 0;
  }
  @media only screen and (max-width: $xs) {
    margin: 23px auto 0;
  }
}

.TitleSection {
  height: 100vh;
  min-height: 701px;
  // margin-top: 90px;
  //background: #124C9A url("~@assets/backgrounds/main_title_section.svg") no-repeat center bottom;
  background-size: cover!important;
  position: relative;

  &[data-default-img = "true"] {
    background-size: contain!important;
  }

  @media only screen and (max-width: $lg) {
    background-size: 105% auto;
    background-position: -8% bottom;
  }
  @media only screen and (max-width: $md) {
    background-size: 141% auto;
    background-position: 57% bottom;
    min-height: 635px;
  }
  @media only screen and (max-width: $xs) {
    // margin-top: 72px;
    min-height: 323px;
  }

  .Container {
    max-width: $md;
    width: 100%;
    margin: 0 auto;
    padding: 90px 0 0 0;
    @media only screen and (max-width: $xs) {
      padding: 72px 0 0 0;
    }

    .Title {
      font-weight: 800;
      font-size: 64px;
      line-height: 64px;
      text-align: center;
      color: #FFFFFF;
      max-width: 642px;
      width: 100%;
      margin: 0 auto;
      padding-top: 78px;

      @media only screen and (max-width: $lg) {
        padding-top: 92px;
      }
      @media only screen and (max-width: $sm) {
        padding-top: 40px;
        font-weight: 800;
        font-size: 56px;
        line-height: 64px;
      }
      @media only screen and (max-width: $xs) {
        font-size: 28px;
        line-height: 34px;
        padding-top: 30px;
      }

    }

    .SubTitle {
      font-size: 18px;
      line-height: 23px;
      color: #FFFFFF;
      max-width: 398px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      padding-top: 20px;

      @media only screen and (max-width: $sm) {
        padding-top: 18px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
        width: 300px;
      }
      @media only screen and (max-width: $xs) {
        padding-top: 12px;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .Button {
      @include button;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ReadMode {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      margin: 75px auto 0;
      width: 200px;

      @media only screen and (max-width: $lg) {
        margin: 54px auto 0;
      }
      @media only screen and (max-width: $sm) {
        margin: 20px auto 0;
      }
      @media only screen and (max-width: $xs) {
        margin: 5px auto 0;
      }

      p {
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        @media only screen and (max-width: $xs) {
          font-size: 14px;
          line-height: 20px;
          font-weight: bold;
          margin: 10px 0;
        }
      }

      i {
        background: url("~@assets/mouse_up.svg") center center no-repeat;
        width: 38px;
        height: 130px;
        @media only screen and (max-width: $xs) {
          background: url("~@assets/sm_mouse_up.svg") center center no-repeat;
          height: 27px;
        }
      }
    }
  }
}

.Structure {
  max-width: $md;
  width: 100%;
  height: auto;
  margin: 145px auto 0;
  position: relative;
  display: flex;
  align-items: flex-end;

  @media only screen and (max-width: $lg) {
    margin: 128px auto 0;
  }
  @media only screen and (max-width: $md) {
    margin: 60px auto 30px;
  }
  @media only screen and (max-width: $sm) {
    margin: 50px auto 25px;
    flex-direction: column;
  }
  @media only screen and (max-width: $xs) {
    margin: 32px auto 0;
  }

  .Description {
    max-width: 353px;
    width: 100%;
    position: absolute;
    top: -17px;

    @media only screen and (max-width: $lg) {
      top: 0;
    }
    @media only screen and (max-width: $md) {
      max-width: 385px;
      padding-left: 40px;
    }
    @media only screen and (max-width: $sm) {
      max-width: unset;
      position: relative;
      padding-left: 40px;
      padding-right: 40px;
    }
    @media only screen and (max-width: $xs) {
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
    }

    &Title {
      font-size: 37px;
      @media only screen and (max-width: $md) {
        font-size: 36px;
        line-height: 44px;
      }
      @media only screen and (max-width: $xs) {
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
      }
    }

    &Text {
      padding: initial;
      margin-top: 8px;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #4F4F4F;
      @media only screen and (max-width: $sm) {
        margin: 11px 0 24px;
      }
      @media only screen and (max-width: $xs) {
        font-size: 12px;
        line-height: 16px;
        margin: 11px 0 29px;
      }
    }
  }

  &Headlines {
    position: absolute;
    display: flex;
    align-items: flex-end;
    max-width: 1121px;
    width: 100%;
    height: 100%;
    @media only screen and (max-width: $sm) {
      position: relative;
    }

    &Controls {
      display: none;
      @media only screen and (max-width: $sm) {
        width: 42%;
        min-width: 320px;
        position: absolute;
        display: flex;
        top: 15px;
        left: 50%;
        justify-content: space-between;
        transform: translate(-50%, 0);
        z-index: 1;
      }
      @media only screen and (max-width: $xs) {
        min-width: 253px;
        top: 9px;
      }
      &Left, &Right {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        background-color: #124C9A;
        background-position: center center;
        background-repeat: no-repeat; 
        cursor: pointer;
        &[data-active = "false"] {
          cursor: default;
          pointer-events: none;
          visibility: hidden;
        }
      }
      &Left {
        background-image: url("~@assets/arrow_prev_white.svg");
        background-position: 45% 50%;
      }
      &Right {
        background-image: url("~@assets/arrow_next_white.svg");
        background-position: 55% 50%;
      }
    }

    &Column {
      width: 100%;
      transition: 0.3s;

      &[data-level = "-1"] {
        margin-left: 1.9%;
        height: 46%;
        @media only screen and (max-width: $sm) {
          h4 {
            background-image: url("~@assets/backgrounds/structures/-1.png");
          }
        }
      }

      &[data-level = "0"] {
        margin-left: 1.9%;
        height: 67.7%;
        @media only screen and (max-width: $sm) {
          h4 {
            background-image: url("~@assets/backgrounds/structures/0.png");
          }
        }
      }

      &[data-level = "1"] {
        margin-left: 1.9%;
        height: 84.7%;
        @media only screen and (max-width: $sm) {
          h4 {
            background-image: url("~@assets/backgrounds/structures/1.png");
          }
        }
      }

      &[data-level = "2"] {
        margin-left: 1.9%;
        height: 98.7%;
        @media only screen and (max-width: $sm) {
          h4 {
            background-image: url("~@assets/backgrounds/structures/2.png");
          }
        }
      }

      @media only screen and (max-width: $sm) {
        position: relative;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        margin: 0 !important;
        ul {
          visibility: visible !important;
        }
        h4 {
          background-size: cover;
          background-repeat: no-repeat; 
          background-position: center center;
          width: 100%;
          height: 67px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff !important;
        }
        &[data-active = "true"] {
          display: flex;
          height: auto;
        }
      }

      &:hover {
        background: #FFFFFF;

        h4 {
          color: #124C9A;
        }

        ul {
          visibility: initial;
        }
      }

      h4 {
        font-family: 'AXP_BOLD', sans-serif;
        font-size: 28px;
        line-height: 50px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #333333;
        padding-left: 20px;
        @media only screen and (max-width: $xs) {
          height: 54px;
        }
      }

      ul {
        padding-top: 22px;
        visibility: hidden;
        @media only screen and (max-width: $md) {
          padding-top: 16px;
        }
        @media only screen and (max-width: $sm) {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          padding-top: 21px;
        }
        @media only screen and (max-width: $xs) {
          padding: 14px 20px;
          text-align: center;
        }

        li {
          list-style: none;
          padding: 7px 20px 7px 20px;
          @media only screen and (max-width: $md) {
            padding: 3px 10px 3px 10px;
          }
          @media only screen and (max-width: $sm) {
            padding: 3px 10px 9px 10px;
          }

          &:first-letter {
            text-transform: uppercase;
          }

          a {
            text-decoration: none;
            text-transform: lowercase;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;
            color: #4F4F4F;
            font-weight: 600;

            @media only screen and (max-width: $md) {
              font-size: 12px;
              line-height: 14px;
            }
            @media only screen and (max-width: $xs) {
              font-size: 14px;
              line-height: 20px;
            }

            &:hover {
              color: #124C9A;
            }
          }
        }
      }
    }
  }

  .Background {
    background: url("~@assets/backgrounds/main_structure.svg") left bottom no-repeat;
    width: 100%;
    height: 708px;
    background-size: contain;

    @media only screen and (max-width: $md) {
      width: 100vw;
      height: 62vw;
    }
    @media only screen and (max-width: $sm) {
      display: none;
    }
  }
}

.Designations {
  height: auto;
  margin-top: 113px;
  position: relative;
  display: flex;
  @media only screen and (max-width: $md) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: $xs) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &Container {
    max-width: $md;
    width: 100%;
    margin: 0 auto;
    padding: 0 40px;
    box-sizing: content-box;
    @media only screen and (max-width: $xs) {
      padding: 0 20px;
    }

    &Icon {
      background: #fff url("~@assets/backgrounds/designations.svg") no-repeat 0 0;
      height: 692px;
      margin-left: 197px;
      background-size: contain;
      @media only screen and (max-width: $md) {
        background-position: bottom right;
        height: 65vw;
      }
      @media only screen and (max-width: $sm) {
        margin-left: 0;
        background-position: bottom center;
        height: 63vw;
      }
      @media only screen and (max-width: $sm) {
        background-image: url("~@assets/backgrounds/sm_designations.svg");
        height: 138vw;
        margin: 52px 0 0 0;
      }
    }

    &Content {
      background: #FFFFFF;
      border-radius: 0 117px 117px 0;
      width: 406.78px;
      height: auto;
      position: absolute;
      top: 22%;
      padding: 18px 0 32px;
      z-index: 1;

      @media only screen and (max-width: $lg) {
        top: 21.4%;
        padding: 14px 0 36px;
      }
      @media only screen and (max-width: $md) {
        top: 0;
      }
      @media only screen and (max-width: $sm) {
        position: relative;
        width: 100%;
        background: transparent;
        border-radius: unset;
        padding: 14px 0 0px;
      }
      @media only screen and (max-width: $xs) {
        padding: 0;
        text-align: center;
      }

      &Title {
        font-weight: 600;
        font-size: 36px;
        line-height: 42px;
        color: #333333;
        @media only screen and (max-width: $md) {
          font-size: 36px;
          line-height: 44px;
        }
        @media only screen and (max-width: $xs) {
          font-weight: bold;
          font-size: 30px;
          line-height: 36px;
        }
      }

      &Description {
        font-size: 16px;
        line-height: 20px;
        color: #333333;
        margin-top: 8px;
        max-width: 372px;
        width: 100%;
        @media only screen and (max-width: $lg) {
          margin-top: 12px;
        }
        @media only screen and (max-width: $sm) {
          max-width: none;
        }
        @media only screen and (max-width: $xs) {
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

.DoBetter {
  margin: 0 auto;
  display: flex;
  height: 460px;
  background: url("~@assets/backgrounds/do_better.svg") bottom left no-repeat;
  margin-top: 130px;
  background-size: contain;
  margin-bottom: 112px;
  @media only screen and (max-width: $lg) {
    margin-top: 127px;
    margin-bottom: 118px;
  }
  @media only screen and (max-width: $md) {
    height: 45vw;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: $sm) {
    background-image: url("~@assets/backgrounds/sm_do_better.svg");
    margin-top: 77px;
    height: 55.2vw;
    margin-bottom: 72px;
  }
  @media only screen and (max-width: $xs) {
    background-image: url("~@assets/backgrounds/xs_do_better.svg");
    margin-top: 30px;
    height: 124.9vw;
    margin-bottom: 45px;
    background-size: 96% auto;
  }

  &Container {
    max-width: $md;
    box-sizing: content-box;
    width: 100%;
    margin: 0 auto;
    padding: 0 40px 0 40px;
    @media only screen and (max-width: $xs) {
      padding: 0 20px 0 20px;
    }

    &About {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media only screen and (max-width: $xs) {
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      &Title {
        font-family: 'AXP_BOLD', sans-serif;
        font-size: 36px;
        line-height: 56px;
        color: #333333;
        @media only screen and (max-width: $xs) {
          font-weight: bold;
          font-size: 30px;
          line-height: 36px;
        }
      }

      &Description {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #4F4F4F;
        max-width: 329px;
        width: 100%;
        text-align: right;
        @media only screen and (max-width: $lg) {
          margin: 6px 0 25px 0;
        }
        @media only screen and (max-width: $xs) {
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          margin: 16px 0 25px 0;
        }
      }

      &Button {
        @include button;
        margin: 11px 0 0;
        max-width: 186px;
        width: 100%;
        text-decoration: none;
        display: flex!important;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: $lg) {
          margin: 4px 0 0;
        }
      }
    }
  }
}
