@import "~@styles/palette.scss";

.NotificationsForm {

  .NotificationsFormHeader {
    padding: 30px 0 0 30px;

    h4 {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
    }

    height: 128px;
    border-bottom: 1px solid #EDEDED;
  }

  .NotificationsFormHeaderField {
    margin-top: 20px;

    label {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: $gray1;
    }
  }

  .NotificationsFormContainer {
    padding: 10px 0 0 30px;

    .NotificationsFormContainerField {
      margin-top: 20px;

      label {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $gray1;
      }
    }
  }

  .NotificationsFormRemoveAccount {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    h4 {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
    }

    .NotificationsFormRemoveAccBtn {
      background: transparent;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: $accentRed;
      max-width: 120px;
      width: 100%;
      margin-top: 20px;
      margin-left: -8px;
    }


    .Logout {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: $gray1;
      max-width: 135px;
      width: 100%;
      margin-top: 20px;
      margin-left: -8px;
      background: transparent;
    }
  }

  .ModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: $gray1;
      max-width: 181px;
      width: 100%;
      margin: 0 0 40px 0;
    }

    .NotificationsFormActionsModal {
      display: flex;
      margin-bottom: 62px;
      width: 100%;
      justify-content: center;
      gap: 20px;

      .NotificationsFormActionsModalSureAction {
        max-width: 154px;
        width: 100%;
        height: 36px;
        border: 1px solid #F2F2F2;
        background: transparent;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $green;
      }

      .NotificationsFormActionsModalNotSureAction {
        max-width: 106px;
        width: 100%;
        height: 36px;
        background: $green;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
      }
    }
  }
}
