@import "~@styles/breakpoints.scss";
@import "~@styles/mixins.scss";
@import '~@styles/palette.scss';

$borderStyle: 1px solid #ECECEC;

@mixin NavLink {
  width: 100%;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 66px;
  position: relative;
  margin-bottom: 10px;
  padding-top: 6px;
  @media only screen and (max-width: $md) {
    font-size: 2.3vw;
    line-height: 2.5vw;
    width: 60px;
    color: rgba(0,0,0,0);
    overflow: hidden;
  }
  @media only screen and (max-width: $xs) {
    width: auto;
    overflow: visible;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    // padding-left: 30px;
    padding-right: 60px;
    color: #333333;
  }
  
  svg {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 27px;
    top: 13px;
  }

  &:hover {
    color: $blue;

    svg {
      path, circle {
        stroke: $blue;
      }
    }
  }
}

@mixin ActiveNavLink {
  color: $blue;
  @media only screen and (max-width: $md) {
    width: 60px;
    overflow: hidden;
    color: rgba(0,0,0,0);
  }
  @media only screen and (max-width: $xs) {
    width: auto;
    overflow: visible;
    color: $blue;
  }

  &:before {
    content: "";
    height: 48px;
    border-left: 2px solid $blue;
    position: absolute;
    left: 0;
    top: 0;
    @media only screen and (max-width: $xs) {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("~@assets/check_mark_blue.svg");
      border: none;
      left: auto;
      top: auto;
      right: 30px;
      width: 10px;
      height: 10px;
    }
  }

  svg {
    path, circle {
      stroke: $blue;
    }
  }
}

.Root {
  display: flex;
  padding: 0 40px 40px 40px;
  @media only screen and (max-width: $xs) {
    flex-direction: column;
    padding: 90px 20px 20px 20px;
  }

  .NavigationSelector {
    display: none;
    @media only screen and (max-width: $xs) {
      display: flex;
      align-items: center;
      width: 200px;
      cursor: pointer;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      svg {
        margin: 0 10px 0 0;
      }
      &::after {
        content: '';
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("~@assets/arrow_open.svg");
        width: 10px;
        height: 10px;
        transform: rotate(90deg);
        margin: 0 0 0 10px;
      }
      &[data-active = "true"] {
        &::after {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .NavigationBlocks {
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: $xs) {
      position: absolute;
      display: none;
      margin-top: 40px;
      left: 0;
      &[data-open = "true"] {
        display: flex;
        background: #FFFFFF;
        border-radius: 0px 6px 6px 0px;
        max-width: 290px;
        z-index: 10;
        box-shadow: 0px 2px 30px rgba(178, 181, 194, 0.29);
      }
    }

    .NavigationBlock {
      display: flex;

      nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 27px;

        a {
          @include NavLink;

          &:last-of-type {
            &:hover {
              color: $blue;

              svg {
                path {
                  fill: $blue;
                }
              }
            }
          }
        }

        .ActiveLink {
          @include ActiveNavLink;
        }
      }
    }

    .SettingBlock {
      display: flex;
      margin-top: 58px;
      @media only screen and (max-width: $xs) {
        margin-top: 0;
      }

      nav {
        a {
          @include NavLink;
        }

        .ActiveLink {
          @include ActiveNavLink;
        }
      }
    }
  }

  .Container {
    @include ContainerLg;
    display: flex;
    border: $borderStyle;
    border-radius: 6px;
    position: relative;
    flex-direction: row;
    height: 100%;
    margin: 120px auto 0;
    padding: 0;
    box-sizing: border-box;
    @media only screen and (max-width: $xs) {
      margin: 20px auto 0;
      flex-direction: column;
    }

    .UserBlock {
      max-width: 232px;
      width: 100%;
      border-right: $borderStyle;
      height: auto;
      flex: 1 auto;
      @media only screen and (max-width: $md) {
        max-width: 76px;
        &[data-open = "true"] {
          max-width: 35vw;
          min-width: 170px;
          a {
            width: auto !important;
            color: #333333;
          }
          .ActiveLink {
            color: $blue;
          }
        }
      }
      @media only screen and (max-width: $xs) {
        max-width: unset;
        border-right: none;
      }

      &Trigger {
        display: none;
        @media only screen and (max-width: $md) {
          display: block;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url("~@assets/arrow_open.svg");
          width: 18px;
          height: 13px;
          margin: 23px auto;
          cursor: pointer;
          &[data-active = "true"] {
            transform: rotate(-180deg);
          }
        }
        @media only screen and (max-width: $xs) {
          display: none;
        }
      }

      .AvatarBlock {
        height: 186px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: $borderStyle;
        flex-direction: column;
        position: relative;
        @media only screen and (max-width: $md) {
          height: 117px;
        }
        @media only screen and (max-width: $xs) {
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          height: auto;
          padding: 10px 16px;
        }

        .AvatarBlockChangeAvatar {
          width: 93px;
          height: 93px;
          border-radius: 50%;
          margin-top: -93px;
          @media only screen and (max-width: $md) {
            width: 48px;
            height: 48px;
            top: 15px;
            margin-top: -48px;
          }
          @media only screen and (max-width: $xs) {
            top: auto;
            left: auto;
            width: 48px;
            margin-top: 0;
            position: absolute;
          }

          & > div {
            height: 100%;
          }

          &:hover {
            span {
              visibility: visible;
            }
          }

          span {
            width: 93px;
            height: 93px;
            display: flex;
            position: absolute;
            background: url("~@assets/avatar_choice.svg") center no-repeat rgba(34, 62, 120, 0.6);
            cursor: pointer;
            border-radius: 50%;
            visibility: hidden;
            @media only screen and (max-width: $md) {
              width: 48px;
              height: 48px;
            }
          }

          input {
            height: 100%;
            outline:0;
            opacity:0;
            width: 93px;
            cursor: pointer;

            @media only screen and (max-width: $md) {
              width: 48px;
            }

            @media only screen and (max-width: $sm) {
              width: 48px;
            }

            @media only screen and (max-width: $xs) {
              width: 48px;
            }
          }
        }

        img {
          max-width: 93px;
          width: 100%;
          height: 93px;
          border-radius: 50%;
          cursor: pointer;
          @media only screen and (max-width: $md) {
            max-width: 48px;
            height: 48px;
          }

          &:hover {
            background-color: #124c9a;
            opacity: 0.2;
          }
        }

        .UserName {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          letter-spacing: -0.0125em;
          color: #828282;
          padding-top: 16px;
          @media only screen and (max-width: $md) {
            font-size: 18px;
            line-height: 22px;
          }
          @media only screen and (max-width: $xs) {
            padding-top: 0;
            margin-left: 16px;
          }
        }
      }
    }

    .ContentBlock {
      width: 100%;
      overflow: hidden;
      // height: inherit;
    }
  }
}
