@import "~@styles/breakpoints.scss";
@import "~@components/Filtration/Filtration.scss";

  .FilterElementComplexityInstallation {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > span {
      margin-right: 11px;
    }

    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start
    }

    .FilterElementComplexityInstallationContent {
      display: flex;
      column-gap: 4px;

      @media only screen and (max-width: $xs) {
        margin: 0 0 0 0;
      }

      .FilterElementMountingIcon {
        background: url("~@assets/filter_icons/filter_mounting.svg") 0 0 no-repeat;;

        @include filterElement;
        @include filterElementContainer;

        &:hover {
          background: url("~@assets/filter_icons/filter_mounting_focus.svg") 0 0 no-repeat;
        }
      }

      .FilterElementMountingIconActive {
        background: url("~@assets/filter_icons/filter_mounting_active.svg") 0 0 no-repeat;

        &:hover {
          background: url("~@assets/filter_icons/filter_mounting_active.svg") 0 0 no-repeat;
        }
      }
    }

    @include filterTitle;
  }
