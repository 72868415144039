@import "~@styles/breakpoints.scss";
@import "~@views/PrivateCabinet/components/PersonalData/PersonalData";

.AdditionalInfo {
  padding: 30px;

  .Form {
    display: flex;
    gap: 30px;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
    }

    .FormContainer {
      max-width: 291px;
      width: 100%;

      .FormContainerField {
        display: flex;
        flex-direction: column;
        margin-right: 30px;
        margin-bottom: 30px;
        width: 100%;

        label {
          @include Label;
          font-family: 'AXP_LIGHT', sans-serif;
        }

        input {
          @include Input;
          height: 36px;
          font-family: 'AXP_REGULAR', sans-serif;
          background: #FFFFFF;
          color: #333333;
          max-width: 291px;

          &::placeholder {
            color: #333333;
          }
        }
      }

      .FormContainerSelectField {
        margin-bottom: 30px;

        label {
          @include Label;
          display: block;
        }
      }

      .FormCalendar {

        input {
          max-width: 165px;
          width: 100%;
          background: #FFFFFF;
        }
      }
    }
  }
}

.CalendarContainer {
  position: relative;
  max-width: 165px;
  width: 100%;

  svg {
    position: absolute;
    right: 13px;
    top: 11px;
    z-index: 1;
    pointer-events: none;
  }
}

.SexSelect {
  max-width: 165px;
  width: 100%;
}

.CitySelect {
  max-width: 226px;
  width: 100%;
  @media only screen and (max-width: $xs) {
    max-width: unset;
  }
}

.Calendar {
  background: #FFFFFF!important;
  border: none!important;
  box-shadow: 0 2px 30px rgba(178, 181, 194, 0.29)!important;

  button {
    visibility: hidden!important;
  }

  :global(.react-datepicker__current-month) {
    display: none!important;
  }
}

:global(.react-datepicker__header) {
  background-color: #FFFFFF!important;
  border: none!important;
}

:global(.react-datepicker__day-names) {
  margin-bottom: 10px!important;
}

:global(.react-datepicker__day-name) {
  font-size: 14px!important;
  line-height: 16px!important;
  color: #828282!important;
  font-family: Roboto-Light, sans-serif!important;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  &:first-letter {
    text-transform: uppercase ;
  }
}

:global(.react-datepicker__day--today) {
  border: 2px solid #124C9A!important;
  box-sizing: border-box!important;
  border-radius: 3px!important;
  background: rgba(18, 76, 154, 0.1)!important;
}

:global(.react-datepicker-popper[data-placement^="bottom"]) {
  margin-top: -30px!important;
}

:global(.react-datepicker__triangle) {
  display: none;
}

:global(.react-datepicker__header__dropdown) {
  display: flex!important;
  justify-content: space-between!important;
  margin-top: 11px!important;
  margin-bottom: 20px!important;
  padding-right: 11px;
}

:global(.react-datepicker__month-read-view--selected-month) {
  font-weight: 600!important;
  font-size: 18px!important;
  line-height: 22px!important;
  color: #333333!important;
  font-family: "Fira_Sans_Regular", sans-serif!important;

  &:first-letter {
    text-transform: uppercase!important;
  }
}

:global(.react-datepicker__year-read-view--selected-year) {
  font-weight: 600!important;
  font-size: 18px!important;
  line-height: 22px!important;
  color: #333333!important;
  font-family: "Fira_Sans_Regular", sans-serif!important;
}

:global(.react-datepicker__day) {
  font-size: 16px!important;
  line-height: 19px!important;
  font-family: Roboto-Regular, sans-serif!important;
  color: #333333!important;
  font-weight: bold;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  height: 29px;
  width: 29px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

:global(.react-datepicker__day--selected) {
  color: #FFFFFF;
}

:global(.react-datepicker__month-read-view) {
  &:first-letter {
    text-transform: uppercase!important;
  }
}

:global(.react-datepicker__day--outside-month) {
  visibility: hidden;
  pointer-events: none;
}
