.RecoveryForm {
  max-width: 387px;
  width: 100%;
  height: 331px;
  box-shadow: 0 2px 30px rgba(178, 181, 194, 0.29);
  border-radius: 6px;
  background: #fff;
  position: fixed;
  right: 0;
  left: 0;
  margin: 0 auto;
  top: 140px;
  z-index: 1;

  &Title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    margin-top: 30px;
    margin-bottom: 24px;
    font-family: 'AXP_BOLD', sans-serif;
  }

  &SubTitle {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    text-align: center;
  }

  &Container {
    max-width: 297px;
    width: 100%;
    margin: 30px auto 0;

    &Field {
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      label {
        color: #828282;
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 8px;
      }

      input {
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        box-sizing: border-box;
        border-radius: 6px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.0125em;
        color: #333333;
        padding-top: 7px;
        padding-bottom: 5px;
        padding-left: 12px;
        position: relative;

        &::placeholder {
          color: #BDBDBD;
        }
      }
    }
  }

  &Actions {
    max-width: 297px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 40px auto 0;

    button {
      background: #1CAB88;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      max-width: 147px;
      height: 36px;
      font-weight: 600;
    }
  }
}

.Close {
  background: url("~@assets/close.svg") 0 0 no-repeat;
  position: absolute;
  top: 18px;
  right: 18px;
  width: 13px;
  height: 13px;
  cursor: pointer;
}

.Form {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  z-index: 1;
}