@import "~@styles/breakpoints.scss";
@import "~@styles/mixins.scss";
@import "~@styles/palette.scss";

.Loader {
  display: flex;
  justify-content: center;
  transform: scale(2);
  margin-bottom: 100px;
}

.Root {
  background: linear-gradient(90deg, rgba(248,248,248,1) 0%, rgba(248,248,248,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
  overflow: hidden;
  @media only screen and (max-width: $sm) {
    background: #FFFFFF;
  }
}

@mixin linkHover {
  &:hover, &:active {
    color: #124C9A;
  }
}

.HandbookEmptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 634px;

  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: $gray1;
    margin: 40px 0 4px;
  }

  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $gray1;
  }
}

.Handbook {
  @include ContainerLg;
  display: flex;
  position: relative;
  height: 100%;
  padding: 90px 40px 40px 40px;
  box-sizing: content-box;
  width: auto;
  @media only screen and (max-width: $sm) {
    padding: 160px 40px 0 40px;
    height: auto;
  }
  @media only screen and (max-width: $xs) {
    padding: 100px 20px 0 20px;
  }

  .MobileTrigger {
    display: none;
    @media only screen and (max-width: $sm) {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      top: 132px;
      &::after {
        content: "";
        background-image: url("~@assets/arrow_down.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 19px;
        height: 12px;
        margin: 0 14px 2px 14px;
      }
      &[data-active = "true"] {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
    @media only screen and (max-width: $xs) {
      top: 90px;
    }
  }

  .Navigation {
    position: relative;
    z-index: 1;
    max-width: 231px;
    width: 100%;
    height: inherit;
    @media only screen and (max-width: $sm) {
      position: absolute;
      background-color: #FFFFFF;
      display: none;
      max-width: unset;
      width: 210px;
      padding: 32px;
      box-shadow: 0px 2px 30px rgba(178, 181, 194, 0.29);
      margin: 20px 0 0 0;
      &[data-active = "true"] {
        display: flex;
      }
    }

    .Container {
      margin-left: 0;

      .Title {
        font-family: 'AXP_BOLD', sans-serif;
        font-size: 24px;
        line-height: 29px;
        color: #333333;
        padding-top: 38px;
        padding-bottom: 24px;
        @media only screen and (max-width: $sm) {
          padding-top: 0;
        }
      }

      .MainMenu {

        .MenuElementActive {
          color: #124C9A !important;
          & > svg {
            transform: rotate(180deg);
          }
        }

        .MainMenuList {
          list-style: none;
          font-size: 18px;
          line-height: 22px;
          font-family: 'AXP_BOLD', sans-serif;
          color: #333333;
          padding-top: 0px;
          padding-bottom: 12px;
          cursor: pointer;
          position: relative;
          
          &[data-level] {
            .MainMenuTitle {
              background-size: cover;
              background-position: center center;
              background-repeat: no-repeat;
              color: #FFFFFF;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 16px;
              width: 105px;
              height: 36px;
              border-radius: 6px;
            }
          }

          &[data-level = "-1"] {
            .MainMenuTitle {
              background-image: url("~@assets/backgrounds/handbook/navigation/-1.svg");
            }
          }
    
          &[data-level = "0"] {
            .MainMenuTitle {
              background-image: url("~@assets/backgrounds/handbook/navigation/0.svg");
            }
          }
    
          &[data-level = "1"] {
            .MainMenuTitle {
              background-image: url("~@assets/backgrounds/handbook/navigation/1.svg");
            }
          }
    
          &[data-level = "2"] {
            .MainMenuTitle {
              background-image: url("~@assets/backgrounds/handbook/navigation/2.svg");
            }
          }

          &:first-letter {
            text-transform: uppercase;
          }

          @include linkHover;

          & > svg {
            width: 12px;
            position: absolute;
            top: 7px;
            height: 8px;
            margin-left: 10px;
            margin-top: 6px;
          }

          &:hover > svg {
            path {
              stroke: #124C9A;
            }
          }

          .SubMenu {
            padding-top: 8px;

            .SubMenuList {
              font-family: "Fira_Sans_Regular", sans-serif;
              font-size: 14px;
              line-height: 20px;
              color: #333333;
              font-weight: bold;
              padding-top: 8.5px;
              padding-bottom: 8.5px;
              position: relative;
              list-style: none;

              @include linkHover;
              display: block;

              .SubMenuTitle {
                display: flex;
              }

              span {
                max-width: 159px;
                width: 100%;
                display: inline-block;

                &:first-letter {
                  text-transform: uppercase;
                }
              }

              & > svg {
                right: 31px;
                width: 10px;
                position: absolute;
                top: 13px;
                height: 7px;
              }

              &:hover {
                svg > path {
                  stroke: #124C9A;
                }
              }

              .SubSubMenu {
                padding-top: 12px;

                li {
                  position: relative;
                  list-style: none;
                  padding-top: 6px;
                  padding-bottom: 6px;

                  &:first-letter {
                    text-transform: uppercase;
                  }

                  a {
                    font-family: "Fira_Sans_Regular", sans-serif;
                    font-size: 14px;
                    line-height: 20px;
                    color: #828282;
                    font-weight: bold;
                    text-decoration: none;
                    padding-left: 20px;
                    display: block;

                    @include linkHover;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .IconContainer {
    width: 20px;
    height: 20px;
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 4px;

    svg {
      top: 6px;
    }

    .IconContainerActive {
      transform: rotate(180deg);
    }
  }

  .HandbookPage {
    padding-top: 37px;
    width: 100%;
    height: 100%;
    padding-left: 36px;
    padding-right: 0;
    background: #FFFFFF;
    @media only screen and (max-width: $sm) {
      padding-left: 0;
    }

    .SectionTitle {
      font-size: 18px;
      line-height: 22px;
      color: #BDBDBD;
      font-weight: bold;
      margin: initial;
    }

    .SectionSubTitle {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: #333333;
      text-transform: lowercase;
      padding-top: 2px;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    .HandbookPageContainer {
      border-top: 1px solid #ECECEC;
      margin-top: 16px;
      margin-bottom: 30px;

      .PageElements {
        margin-top: 19px;
        display: flex;
        flex-wrap: wrap;
        // gap: 3%;
        @media only screen and (max-width: $xs) {
          &[data-mode = "2"] {
            justify-content: space-between;
            .Card {
              width: 46% !important;
              margin: 0 0 20px 0 !important;
            }
          }
        }

        & > span {
          display: flex;
          flex-wrap: wrap;
          gap: 40px;
          width: 100%;
        }

        .Card {
          max-width: unset;
          width: 22.7%;
          margin: 0 3.06% 40px 0;
          @media only screen and (max-width: $md) {
            width: 31.33%;
            margin: 0 3% 18px 0;
          }
          @media only screen and (max-width: $xs) {
            width: 100%;
            margin: 0 0 20px 0;
          }

          img {
            max-width: unset;
          }

          &:nth-child(4n) {
            margin: 0 0 40px 0;
            @media only screen and (max-width: $md) {
              margin: 0 3% 18px 0;
            }
            @media only screen and (max-width: $xs) {
              margin: 0 0 20px 0;
            }
          }

          &:nth-child(3n) {
            @media only screen and (max-width: $md) {
              margin: 0 0 18px 0;
            }
            @media only screen and (max-width: $xs) {
              margin: 0 0 20px 0;
            }
          }
        }
      }

      .HandbookControls {
        display: none;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: $xs) {
          display: flex;
        }
        .FiltersButton {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #ECECEC;
          box-sizing: border-box;
          color: #828282;
          cursor: pointer;
          border-radius: 5px;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          height: 33px;
          padding: 4px 7px;
          margin: 18px 0 0 0;
          &:before {
            content: "";
            width: 15px;
            height: 15px;
            display: block;
            background-image: url("~@assets/filter_gray.svg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            margin: 0 13px 0 0;
          }
          &:hover, &:active, &[data-active = "true"] {
            background-color: #124C9A;
            color: #FFFFFF;
            &:before {
              background-image: url("~@assets/filter_white.svg");
            }
          }
        }
        .Modes {
          display: flex;
          margin: 18px 0 0 0;
          .ModesX1, .ModesX2 {
            background-color: #FFFFFF;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 51% auto;
            border: 1.5px solid #ECECEC;
            box-sizing: border-box;
            border-radius: 26px;
            width: 33px;
            height: 33px;
            cursor: pointer;
            margin: 0 0 0 4px;
            &:hover, &:active, &[data-active = "true"] {
              background-color: #124C9A;
            }
          }

          .ModesX1 {
            background-image: url("~@assets/mode_x1.svg");
          }
          .ModesX2 {
            background-image: url("~@assets/mode_x2.svg");
          }
        }
      }

      .HandbookFiltration {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;
        justify-content: flex-start;
        @media only screen and (max-width: $xs) {
          display: none;
          position: absolute;
          background: #FFFFFF;
          box-shadow: 0px 2px 30px rgba(178, 181, 194, 0.29);
          border-radius: 6px;
          z-index: 1;
          width: 196px;
          padding: 27px 16px;
          &[data-active = "true"] {
            display: flex;
            flex-direction: column;
          }
        }

        .HandbookFiltrationContainer {
          margin-right: 15px;
          margin-top: 12px;
          @media only screen and (max-width: $xs) {
            margin: 0 0 20px 0;
          }
        }

        .FilterElementLifeTime {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin: 12px 0 0 0;

          span {
            font-size: 14px;
            line-height: 17px;
            font-weight: bold;
            color: #333333;
            padding-right: 9px;
          }

          .FilterElementInputContainer {
            height: 28px;
            display: flex;
            margin-right: 0;
            @media only screen and (max-width: $xs) {
              margin-top: 8px;
            }

            .FilterElementLifeTimeForm {
              display: flex;

              //& > div:nth-child(1) {
              //  input {
              //    border-radius: 5px 0 0 5px;
              //    border-right: none;
              //  }
              //}
              //
              //& > div:nth-child(2) {
              //  input {
              //    border-radius: 0 5px 5px 0;
              //  }
              //}
            }

            .ResetFilter {
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              color: #124C9A;
              display: flex;
              align-items: center;
              margin-left: 20px;
              cursor: pointer;
            }

            label {
              background: #F9F9F9;
              border-radius: 5px 0 0 5px;
              box-sizing: border-box;
              max-width: 39px;
              width: 100%;
              border: 1px solid #E0E0E0;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #BDBDBD;
              font-size: 14px;
              line-height: 17px;
              font-weight: bold;
            }

            .FilterElementInput {
              height: 28px;
              max-width: 39px;
              width: 100%;

              input {
                max-width: 39px;
                width: 100%;
                background: #fff;
                font-size: 14px;
                line-height: 17px;
                color: #333333;
                border: 1px solid #E0E0E0;
                text-align: center;
                background: #FFFFFF;
                border-radius: 5px;
                &[value = ""] {
                  background: #F9F9F9;
                }
              }
            }
          }
        }
      }
    }
  }
}

.SkeletonItems {
  max-width: 243px;
  width: 100%;
  height: 361px;
}

.SkeletonNavigationItems {
  height: 36px;
  max-width: 131px;
  width: 100%;
  margin-bottom: 12px;
}
